import { docData, doc, collectionChanges } from "rxfire/firestore"
import { bindCallback, of, concat, from, Subject, merge as mergeN, combineLatest } from 'rxjs'
import { catchError, filter, map, flatMap, take, merge  } from 'rxjs/operators'
import axios from 'axios'
import { randomElement, clone, capitalize, mergeFields, formatAddress } from './Util.js'
import { Moves, Move, createMove } from './Moves.js'
import { consoleLog } from './Platform.js'
import {LinkPreviewer} from "./LinkPreviewer";
import phone from 'phone'
import { isDesktop } from './Platform.js'
import { P2P } from './PC.js'
import { resolveDialect } from './Lang.js'
import { observeMicInput } from './Audio.js'
import { encodeMP3 } from './MP3.js'
import { saveAs } from 'file-saver'
import { streamReply } from './Stream.js'
import { unmute } from './unmute.js'

let server
if (false) {
  server = 'http://192.168.1.106:8080'
} else {
  server = 'https://functions-o4pon4eraa-uc.a.run.app'
}

try {
  speechSynthesis.getVoices()
} catch (ignored) {
  
}
let LANG
const MAX_TOKENS = 30
const SOURCE = new URLSearchParams(window.location.search).get('src')
const WHISPER = new URLSearchParams(window.location.search).get('whisper')
const Token = new URLSearchParams(window.location.search).get('token')
const InviteCode = new URLSearchParams(window.location.search).get('referralCode')

const delay = seconds => new Promise(resolve => setTimeout(resolve, seconds*1000))

const xmlEscape = xml => {
  if (!xml) return ''
  return xml.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
}

const xml2json = xml => {
  try {
    var obj = {};
    if (xml.children.length > 0) {
      for (var i = 0; i < xml.children.length; i++) {
        var item = xml.children.item(i);
        var nodeName = item.nodeName;

        if (typeof (obj[nodeName]) == "undefined") {
          obj[nodeName] = xml2json(item);
        } else {
          if (typeof (obj[nodeName].push) == "undefined") {
            var old = obj[nodeName];

            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(xml2json(item));
        }
      }
    } else {
      obj = xml.textContent;
    }
    return obj;
  } catch (e) {
      console.log(e.message);
  }
}

class Peer {

  constructor (user, pc) {
    this.pc = pc
  }

  enableAudio = value => {
    this.audioEnabled = value
  }

  sendAudio = buffer => {
    if (this.audioDC && this.audioEnabled) {
      this.audioDC.send(buffer)
    }
  }

  observeAudio = () => {
    if (!this.audioSubject) {
      this.audioSubject = new Subject()
      this.pc.openDataChannel('audio').then(dc => {
        this.audioDC = dc
        this.audioDC.observeMessages().subscribe(message => {
          this.audioSubject.next(message)
        })
      })
    }
    return this.audioSubject
  }
}

class WhisperVoiceRecognizer {

  instructionSubject = new Subject()
  isActiveSubject = new Subject()
  isActive = false

  observeInstruction = () => {
    return this.instructionSubject
  }

  setLang = lang => {
    this.lang = lang
    debugger
  }

  observeIsActive = () => {
    return concat(this.isActive, this.isActiveSubject)
  }

  start = async () => {
    if (!this.vad) {
      this.vad = await window.vad.MicVAD.new({
        onSpeechEnd: async audio => {
          console.log("speech end", this.lang)
          const blob = encodeMP3([audio])
          //saveAs(blob, "audio.mp3")
          //return
          const file = new File([blob], 'audio.mp3', {
            type: 'audio/mp3'
          })
          const response = await axios.post('http://localhost:8080/transcribe?lang='+(this.lang || ''),
                                            file)
          const { text } = response.data
          this.instructionSubject.next(text)
        }
      })
    }
    this.vad.start()
    this.isActiveSubject.next(this.isActive = true)
  }

  stop = () => {
    this.isActive = false
    this.isActiveSubject.next(false)
    this.vad.pause()
  }
}


class SystemVoiceRecognizer {

  constructor (autocorrect) {
    this.autocorrect = autocorrect
  }

  instructionSubject = new Subject()

  observeInstruction = () => {
    return this.instructionSubject
  }

  onResult = async event => {
    let prompt = ''
    console.log("onResult", event)
    for (var i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) {
        prompt += event.results[i][0].transcript
        break
      }
    }
    this.instructionSubject.next(prompt)
  }

  stop = () => {
    this.active = false
    console.log("STOP RECOGNITION")
    if (this.recognition) {
      this.recognition.abort()
      this.recognition.stop()
    }
    this.onActiveSubject.next(false)
  }

  start = () => {
    if (this.active) return
    this.active = true
    if (!this.recognition) {
      const recognition = new window.webkitSpeechRecognition()
      if (LANG) {
        recognition.lang = LANG
      }
      console.log("LANG", LANG)
      recognition.continuous = true
      recognition.interimResults = false
      recognition.onstart = this.onStart
      recognition.onresult =  this.onResult
      recognition.onerror = this.onError
      recognition.onend = this.onEnd
      this.recognition = recognition
    }
    this.recognition.start()
    console.log("START RECOGNITION")
    this.onActiveSubject.next(true)
  }

  onError = event =>{
    console.log('error:', event)
  }

  onActiveSubject = new Subject()

  observeIsActive = () => {
    return concat(of(this.active), this.onActiveSubject)
  }

  onEnd = event => {
    console.log('end:', event)
    if (this.active) {
      this.active = false
      this.start()
    }
  }

  setLang = lang => {
    if (LANG != lang) {
      const wasActive = this.isActive
      debugger
      if (wasActive) {
        this.stop()
      }
      LANG = lang
      this.recognition = null
      if (wasActive) {
        this.start()
      }
    }
  }
}

class VoiceRecognizer {
  setLang = lang => {
    this.lang = lang
    if (this.impl) {
    }
  }

  canUseWhisper = () => {
    return false
  }

  start = () => {
    if (!this.impl) {
      if (this.canUseWhisper()) {
        this.impl = new WhisperVoiceRecognizer()
      } else {
        this.impl = new SystemVoiceRecognizer(this.autocorrect)
      }
      this.sub1 = this.impl.observeInstruction().subscribe(instruction => {
        this.instructionSubject.next(instruction)
      })
      this.sub2 = this.impl.observeIsActive().subscribe(isActive => {
        this.isActiveSubject.next(isActive)
      })
    }
  }
  stop = () => {
    this.impl.stop()
    this.impl = null
    this.sub1.unsubscribe()
    this.sub2.unsubscribe()
    this.sub1 = null
    this.sub2 = null
  }

  instructionSubject = new Subject()
  isActiveSubject = new Subject()
  
  observeInstruction = () => {
    return this.instructionSubject
  }

  observeIsActive = () => {
    const impl = this.impl
    if (impl) {
      return concat(impl.isActive, this.isActiveSubject)
    }
    return this.isActiveSubject
  }
}

export class Me {
  isNative = () => {
    return typeof window !== 'undefined' && (window.ReactNativeWebView ||
                                             (window.webkit &&
                                              window.webkit.messageHandlers &&
                                              window.webkit.messageHandlers.interOp))
  }

  sendNativeMessage = msg => {
    if (this.isNative()) {
      //window.ReactNativeWebView.postMessage(JSON.stringify(msg))
    }
  }

  isKeyboardExtension = () => {
    return !!Token 
  }

  isContainingApp = () => {
    return !Token 
  }

  nativeLog = msg => {
    if (this.isNative()) {
      this.sendNativeMessage({
        type: 'log',
        message: msg
      })
    } else {
      consoleLog(msg)
    }
  }

  verifyAppleReceipt = async (transactionId, receiptData) => {
    console.log("verifyAppleReceipt", transactionId, receiptData)
    const func = this.firebase.functions().httpsCallable('verifyAppleReceipt')
    const response = await func({transactionId, receiptData})
    debugger
    const { verified } = response.data
    if (verified) {
      this.finishAppleTransaction(transactionId)
    }
    return response.data
  }

  finishAppleTransaction = transactionId => {
    console.log("finish apple transaction", transactionId)
    const reqId = String(++this.reqId)
    const msg = {
      type: 'verified',
      transactionId,
      reqId
    }
    const p =  new Promise((resolve, reject) => {
      this.ops[reqId] = {
        resolve, reject
      }
      window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
    })
    p.then(() => {
      console.log('transaction complete', transactionId)
    })
    return null
  }

  verified = {}
  verifiedTransactionSubject = new Subject()
  
  observeVerifiedTransactions = () => {
    return concat(from(Object.keys(verified)), this.verifiedTransactionSubject)
  }

  refreshingToken = false
  tokenRefreshSubject = new Subject()
  observeKeyboardLogin = () => {
    if (this.refreshingToken) {
      return concat(of(this.refreshingToken), this.tokenRefreshSubject)
    }
    return this.tokenRefreshSubject
  }

  pageRequestSubject = new Subject()

  observePageRequest = () => {
    return this.pageRequestSubject
  }
  
  nativeInit () {
    if (!this.nativeInitDone) {
      this.nativeInitDone = true
      if (typeof window !== 'undefined' && window.ReactNativeWebView) {
        this.nativeLog("native init done: "+window.postMessage);
        this.sendNativeMessage({
          type: 'config',
          config: this.config
        })
      }
      window.openHomePage = () => {
        this.requestedPage = 'home'
        this.pageRequestSubject.next("home")
      }
      window.ask = (question) => {
        debugger
        alert("ask")
        this.requestedPage = 'ask'
        this.pageRequestSubject.next("ask")
      }
      window.onAppleTransactionFinished = (reqId) => {
        const op = this.ops[reqId]
        delete this.ops[reqId]
        if (op) {
          op.resolve()
        }
      }
      window.returnDeviceId = id => {
        if (!this.self) {
          this.firebase.auth().signInWithCustomToken(id)
        }
      }
      window.handleInvitation = link => {
        this.handleInvitation(link)
      }
      window.handleReferral = invitedBy => {
        this.handleReferral(invitedBy)
      }
      window.refreshToken = () => {
        if (!this.self) {
          return
        }
        this.refreshingToken = true
        this.tokenRefreshSubject.next(true)
        const start = Date.now() / 1000
        this.getIdToken().then(async (token) => {
          const now = Date.now() / 1000
          const secs = Math.max((start + 1.0) - now, 0)
          await delay(secs)
          if (window.webkit && window.webkit.messageHandlers) {
            const msg = {
              type: 'token',
              token
            }
            window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
          }
          this.tokenRefreshSubject.next(this.refreshingToken = false)
        }).catch (err => {
          console.error(err)
          this.tokenRefreshSubject.next(this.refreshingToken = false)
        })
      }
      window.sendButtons = () => {
        this.sendButtons()
      }
      window.verifyAppleTransaction = (transactionId, jws) => {
        console.log("verifyAppleTransaction", transactionId, jws)
        this.verifyAppleReceipt(transactionId, jws)
        this.verified[transactionId] = true
        this.verifiedTransactionSubject.next(transactionId)
        return null
      }
      window.onReceipt = (reqId, receiptAsBase64) => {
        const op = this.ops[reqId]
        delete this.ops[reqId]
        const p = this.verifyAppleReceipt(undefined, receiptAsBase64)
        if (op) {
          p.then(op.resolve).catch(op.reject)
        }
        return null
      }
      window.onPurchaseResult = (reqId, transactionId, purchased, jws) => {
        console.log("onPurchaseResult", reqId, transactionId, purchased, jws)
        const op = this.ops[reqId]
        delete this.ops[reqId]
        setTimeout(() => op.resolve({transactionId, purchased, jws}))
        return null
      }
      window.edit = (document, cursorOffset, buttonId) => {
        console.log("edit ", document, cursorOffset, buttonId)
        this.keyboardActive = true
        this.keyboardActiveSubject.next(true)
        this.keyboardInput = {
          document, cursorOffset, buttonId
        }
        this.keyboardSubject.next(this.keyboardInput)
        this.reportProgress('appEdit')
      }
      window.willEnterBackground = () => {
        this.keyboardActive = false
        this.keyboardActiveSubject.next(false)
      }
      window.requestFullAccess = () => {
        this.fullAccessRequested = true
        this.fullAccessRequestSubject.next(true)
      }
      window.reportKeyboardStatus = (isInstalled, hasFullAccess) => {
        this.setKeyboardStatus(isInstalled, hasFullAccess)
        if (isInstalled) {
          this.reportProgress('keyboardInstalled')
        }
        if (hasFullAccess) {
          this.reportProgress('hasFullAccess')
        }
      }
      window.shareButton = (id, instruction, category) => {
        this.receiveSharedButton(id)
        this.reportProgress('shareButton')
      }
    }
  }

  openKeyboardSettings = () => {
    if (window.webkit && window.webkit.messageHandlers) {
      const msg = {
        type: 'settings',
      }
      window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
    }
  }

  sendReferralCode = (code) => {
    if (window.webkit && window.webkit.messageHandlers) {
      const msg = {
        type: 'referralCode',
        referralCode: code,
        origin: window.location.origin
      }
      window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
    }
  }

  fullAccessRequested = false
  fullAccessRequestSubject = new Subject()
  observeFullAccessRequested = () => {
    return concat(of(this.fullAccessRequested, this.fullAccessRequestSubject))
  }

  keyboardStatusSubject = new Subject()

  hasFullAccess = () => this.keyboardStatus && this.keyboardStatus.hasFullAccess

  setKeyboardStatus = (isInstalled, hasFullAccess) => {
    if (hasFullAccess) {
      this.fullAccessRequested = false
    }
    this.keyboardStatus = {
      isInstalled,
      hasFullAccess
    }
    this.keyboardStatusSubject.next(this.keyboardStatus)
  }

  observeKeyboardStatus() {
    if (this.keyboardStatus) {
      return concat(of(this.keyboardStatus), this.keyboardStatusSubject)
    }
    return this.keyboardStatusSubject
  }

  nativeLogin = () => {
    if (window.webkit && window.webkit.messageHandlers) {
      const msg = {
        type: 'nativeLogin'
      }
      window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
    }
  }

  sendButtons = (buttons) => {
      if (window.webkit && window.webkit.messageHandlers) {
        const msg = {
          type: 'buttons',
          buttons: JSON.stringify({buttons})
        }
        window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
      }
  }

  sendReady = (token) => {
      if (window.webkit && window.webkit.messageHandlers) {
        const msg = {
          type: 'ready',
          ready: true,
          token
        }
        window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
      }
  }

  sendCustomToken = (token) => {
    if (window.webkit && window.webkit.messageHandlers) {
        const msg = {
          type: 'customToken',
          customToken: token
        }
        window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
      }
  }

  sendActive = () => {
      if (window.webkit && window.webkit.messageHandlers) {
        const msg = {
          type: 'active',
          active: true,
        }
        window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
      }
  }

  customToken = null
  customTokenTimestamp = 0
  getIdToken = async () => {
    return await this.self.getIdToken(true)
  }
  
  getCustomToken = async () => {
    const now = Date.now()
    if(now - this.customTokenTimestamp > 30 * 60 * 1000) {
      this.customTokenTimestamp = now
      this.customToken = null
    }
    if (this.customToken) return this.customToken
    const func = this.firebase.functions().httpsCallable('getCustomToken')
    const result = await func({})
    const { customToken } = result.data
    this.customToken = customToken
    return this.customToken
  }

  keyboardIsReady = async (keyboard) => {
    const wasReady = this.keyboard != null
    this.keyboard = keyboard
    if (this.isNative()) {
      this.sendActive()
    }
    if (wasReady) return
    if (!this.isNative()) {
      if (false) {
        const doc = "This is neat 😊😃"
        //const doc = '安特记住了威廉·莎士比亚的智慧格言：“过去的就是序幕！”'
        //const doc = "Ciao! Mi chiamo Bianca, e posso leggerti qualsiasi testo tu mi scriva."
        //window.edit(doc, doc.length - 30)
      } else {
        //window.edit("", 0)
      }
    }
  }

  cancelKeyboard = () => {
    const msg = {
    }
    if (this.isNative()) {
      window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
    }
    this.keyboardActive = false
    this.keyboardActiveSubject.next(false)
  }

  cancelKeyboardOutput = document => {
    const msg = {
      type: 'cancel',
    }
    this.keyboardActive = false
    this.keyboardActiveSubject.next(false)
    if (this.isNative()) {
      window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
    }
  }
  
  sendKeyboardOutput = document => {
    const msg = {
      type: 'document',
      document: JSON.stringify(document)
    }
    debugger
    this.keyboardActive = false
    this.keyboardActiveSubject.next(false)
    if (this.isNative()) {
      window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
    }
  }

  keyboardSubject = new Subject()

  observeKeyboard = () => {
    if (this.keyboardInput) {
      let value = this.keyboardInput
      this.keyboardInput = null
      return concat(of(value), this.keyboardSubject)
    }
    return this.keyboardSubject
  }

  keyboardActive = false
  keyboardActiveSubject = new Subject()

  observeKeyboardIsActive() {
    return concat(of(this.keyboardActive), this.keyboardActiveSubject)
  }
  
  constructor (firebase, config) {
    this.firebase = firebase
    this.config = config
    const auth = this.firebase.auth();
    auth.onAuthStateChanged(this.onAuthStateChanged);
    this.onAuthStateChanged(auth.currentUser);
    this.config = config
    window.postMessage = this.onNativeMessage
    window.observeContactOnline = this.observeContactOnline
    this.linkPreviewer = new LinkPreviewer(firebase);
    this.nativeInit()
    window.blockInput = () => {
    }
    window.unblockInput = () => {
    }
    if (Token) {
      this.firebase.auth().signInWithCustomToken(Token)
    }
    window.addEventListener('resize', this.applyOrientation)
    this.applyOrientation()
    this.connectivitySub = this.observeServerConnectivity().subscribe(isReachable => {
      console.log("server is reachable", isReachable)
    })
    if (this.isAskAppOnly()) {
      debugger
      console.log("signing in anonymously")
      this.firebase.auth().signInAnonymously()
    }
  }

  applyOrientation = () => {
    if (window.innerHeight > window.innerWidth) {
      this.orient = 'portrait'
    } else {
      this.orient = 'landscape'
    }
    this.orientSubject.next(this.orient)
  }

  orientSubject = new Subject()
  observeOrientation() {
    if (this.orient) {
      return concat(of(this.orient), this.orientSubject)
    }
    return this.orientSubject
  }


  shareMove = async move => {
    const url = await this.getVideoURL(move)
    this.sendNativeMessage({
      type: 'shareFile',
      id: move.getName(),
      url
    })
  }

  getVideoURL = async move => {
    const id = move.getDatabaseId()
    const filename = `Moves/${this.self.uid}/${id}/Video.mp4`
    const storage = this.firebase.storage()
    const ref = storage.ref(filename)
    return await ref.getDownloadURL()
  }

  getMoveId = move => {
    const compileTracks = (move, tracks) => {
      if (move.moves) {
	move.moves.map(x => compileTracks(x, tracks))
      } else {
	tracks.push(move)
      }
    }
    const tracks = []
    compileTracks(move, tracks)
    const id = tracks.map(track => track.move.id).join('+')
    return id
  }

  getComboMoveVideoURL = async move => {
    const id = this.getMoveId(move)
    const filename = `Moves/Rendered/${id}/Video.mp4`
    const storage = this.firebase.storage()
    const ref = storage.ref(filename)
    return await ref.getDownloadURL()
  }

  providerTypeFilter = providerType => specialty => filterSpecialty(specialty, providerType)

  observeCustomer = () => {
    return doc(this.firebase.firestore().collection('Customers').doc(this.self.uid)).pipe(map(snap => {
      return snap.exists ? snap.data() : null
    }))
  }

  observePaymentMethods = () => {
    return this.observeCustomer().pipe(map(customer => {
      if (customer) {
        let { paymentMethods, selectedPaymentMethod } = customer
        if (paymentMethods && selectedPaymentMethod) {
          selectedPaymentMethod = paymentMethods.find(x => x.id === selectedPaymentMethod)
        }
        return { paymentMethods, selectedPaymentMethod }
      }
      return {}
    }))
  }

  reqs = []
  callId = 0
  nativeCall = (type, data) => {
    const id = ++this.callId
    return new Promise(resolve => {
      this.reqs[id] = resolve
      const call = {
        type,
      }
      call[type] = data
      this.sendNativeMessage({
        type: 'call',
        reqId: id,
        call
      })
    })
  }

  getCurrentLocation = () => {
    if (typeof window !== 'undefined' && window.ReactNativeWebView) {
      return this.nativeCall({
        type: 'location'
      }).then(response => {
        return response.coords
      })
    }
    return getCurrentPosition()
  }

  observeMoves = () => {
    return from(Moves.map(move => {
      return {
        type: 'added',
        move
      }
    }))
  }

  testServerEdit = async () => {
    const func = this.firebase.functions().httpsCallable('edit')
    const result = await func({
      input: "",
      instruction: "add emojis",
    })
    console.log(result.data)
    debugger
  }

  searchMusic = async term => {
    const func = this.firebase.functions().httpsCallable('search')
    const result = await func({term})
    console.log(JSON.stringify(result, null, ' '))
    return result.data.results
  }

  getOnsets = async song => {
    const func = this.firebase.functions().httpsCallable('getOnsets')
    const result = await func({song})
    const q = this.firebase.firestore().collection('Onsets').where('id', '==', String(song.id))
    const { docs } = await q.get()
    return docs[0].data()
  }

  observeBaseMoves = () => {
    //if (true) return mergeN(this.observeMoves(), this.observeBaseMovesFromDb())
    let ob = this.observeBaseMovesFromDb()
    if (SOURCE) {
      ob = ob.pipe(filter(change =>  SOURCE == change.move.getSource()))
    } else {
      ob = ob.pipe(filter(change => change.move.getSource()))
    }
    return ob
  }
  
  observeBaseMovesFromDb = () => {
    const c = this.firebase.firestore().collection('BaseMoves')
    return collectionChanges(c).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const id = doc.id
        const data = doc.data()
        data.id = id
        return {
          type,
          move: createMove(data)
        }
      })
    }))
  }

  canSaveMove = move => {
    const result = move.getOwner() === this.self.uid
    debugger
    return result
  }

  observeMyMoves = (uid) => {
    let q = this.firebase.firestore().collection('MyMoves')
    if (uid) {
      q = q.where('uid', '==', uid)
    }
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const id = doc.id
        const data = doc.data()
        const move = createMove(data.move)
        move.opts.id = id
        move.setOwner(uid)
        return {
          type,
          move 
        }
      })
    }))
  }

  observeAllMoves = () => {
    return mergeN(this.observeBaseMoves(), this.observeMyMoves())
  }

  saveAs = async (blob, filename) => {
    if (window.ReactNativeWebView) {
      const storage = this.firebase.storage()
      const ref = storage.ref(`Uploads/${this.self.uid}/${filename}`)
      await ref.put(blob)
      const url = await ref.getDownloadURL()
      await this.nativeCall('downloadFile', {
        url: url,
        name: filename,
        mimeType: blob.type
      })
      await ref.delete()
    } else {
      saveAs(blob, filename)
    }
  }

  download = async (move, filename) => {
    consoleLog("downloading move", move)
    const id = move.getDatabaseId()
    const uid = move.getOwner()
    let path
    if (true || !id) {
      consoleLog('calling renderMove')
      const func = this.firebase.functions().httpsCallable('renderMove')
      const result = await func( { move: move.toJSON() } )
      const { storagePath } = result.data
      consoleLog('renderMove storagePath', storagePath)
      path = storagePath
    } else {
      path = `Moves/${this.self.uid}/${id}/Video.mp4`
    }
    consoleLog("path", path)
    const ref = this.firebase.storage().ref(path)
    const url = await ref.getDownloadURL()
    consoleLog("got download url", url)
    if (this.isNative()) {
      await this.nativeCall('downloadFile', {
        url,
        name: 'Video.mp4',
        mimeType: 'video/mp4'
      })
    } else {
      saveAs(url, filename)
    }
  }

  deleteMove = async move => {
    const c = this.firebase.firestore().collection('MyMoves')
    const id = move.getDatabaseId()
    const ref = c.doc(id)
    return await ref.delete()
  }

  saveMove = async move => {
    const c = this.firebase.firestore().collection('MyMoves')
    const id = move.getDatabaseId()
    let ref
    if (!id) {
      ref = c.doc()
    } else {
      ref = c.doc(id)
    }
    const json = move.toJSON()
    debugger
    const data = {
      uid: this.self.uid,
      move: json,
      lastUpdated: Date.now()
    }
    await ref.set(data)
    return ref.id
  }

  getLocation = async opts => {
    if (!opts) return this.getCurrentLocation()
    const { latitude, longitude, address } = opts
    consoleLog(opts)
    debugger
    const func = this.firebase.functions().httpsCallable('getLocation')
    const result = await func({
      lat: latitude,
      lng: longitude,
      address
    })
    const { results } = result.data
    const { geometry, address_components } = results[0]
    const streetNumber = address_components.find(x => x.types.find(y => y == 'street_number')).short_name
    const street = address_components.find(x => x.types.find(y => y == 'route')).short_name
    let suite = ''
    const subPremise = address_components.find(x => x.types.find(y => y == 'subpremise'))
    if (subPremise) {
      suite = `, ${subPremise.short_name}`
    }
    let lat, lng
    if (geometry) {
      lat = geometry.location.lat
      lng = geometry.location.lng
    }
    return {
      latitude: lat,
      longitude: lng,
      address: `${streetNumber} ${street}${suite}`,
      address_components
    }
  }
  
  onNativeMessage = json => {
    //this.nativeLog('onNativeMessage: ' + json)
    //if (json.source) return
    consoleLog('onNativeMessage', json)
    let msg
    try {
      msg = JSON.parse(json)
    } catch (err) {
      this.nativeLog('JSON.parse failed: ' + err.message)
      return
    }
    if (msg.type === 'token') {
      this.saveToken(msg.token)
    } else if (msg.type === 'notification') {
      //this.nativeLog("received not: " + msg.notification.data.type)
      this.notificationSubject.next(msg.notification)
    } else if (msg.type === 'safeArea') {
      window.safeAreaInsets = msg.safeArea
      //this.nativeLog("window.safeAreaInsets=>"+window.safeAreaInsets);
    } else if (msg.type === 'url') {
      //alert("initial url: " + msg.url)
      this.url = msg.url
      this.urlSubject.next(this.url)
    } else if (msg.type === 'creds') {
      this.creds = msg
      this.credsSubject.next(this.creds)
    } else if (msg.type === 'response') {
      const resolve = this.reqs[msg.reqId]
      if (resolve) {
        delete this.reqs[msg.reqId]
        resolve(msg.response)
      }
    }
  }

  utcOffset = -(new Date().getTimezoneOffset()*60*1000)

  saveToken = async token => {
    try {
      const firebase = this.firebase
      const func = firebase.functions().httpsCallable('saveToken')
      const result = await func({
        token: token,
        utcOffset: this.utcOffset,
      })
      debugLog(result)
    } catch (err) {
      console.error(err)
    }
  }
  
  credsSubject = new Subject()
  
  observeCreds = () => {
    if (this.creds) return concat(of(this.creds), this.credsSubject)
    return this.credsSubject
  }

  setStatusBarColor = color => {
    consoleLog('set status bar color:', color)
    this.sendNativeMessage({
      type: 'statusBarColor',
      color: color 
    })
  }

  selfSubject = new Subject()

  observeSelf = () => {
    const existing = this.self ? [this.self] : []
    return concat(existing, this.selfSubject)
  }

  getIOSAppStoreReceipt = () => {
    const reqId = String(++this.reqId)
    const msg = {
      type: 'receipt',
      reqId
    }
    console.log(msg)
    window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
  }

  sendKeyboardSettings = (settings) => {
    const {
      autoCorrection,
      hapticFeedback,
      audioFeedback 
    } = settings
    if (this.isNative()) {
      const msg = {
        type: 'keyboardSettings',
        autoCorrection,
        hapticFeedback,
        audioFeedback
      }
      window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
    }
  }

  onAuthStateChanged = user => {
    if (user && this.user && user.uid == this.user.uid) {
      this.self = user
      return
    }
    this.referralCode = undefined
    this.apiKey = undefined
    this.self = user
    console.log('self', user)
    this.selfSubject.next(user)

    if (this.self) {
      this.firebase.firestore().collection('Admin').where('uid', '==', this.self.uid).get().then( ({ docs }) => {
        this.isAdmin = docs.length > 0
      }).catch(ignored => {
      })
      this.firebase.firestore().collection('Review').where('uid', '==', this.self.uid).get().then( ({ docs }) => {
        this.isReview = docs.length > 0
      }).catch(ignored => {
      })
      this.profileSub = this.observeProfile().subscribe(profile => {
        this.profile = profile
      })
      this.getAllButtons().then(({buttons}) => {
        debugger
        if (buttons) {
          this.sendButtons(buttons)
        }
      })
      if (this.isNative()) {
        this.getIdToken().then(token => {
          this.sendReady(token)
        })
        this.getCustomToken().then(token => {
          this.sendCustomToken(token)
        })
        this.getReferralCode().then(code => {
          this.sendReferralCode(code)
        })
        this.getKeyboardSettings().then(keyboardSettings => {
          this.sendKeyboardSettings(keyboaredSettings)
        })
        this.getIOSAppStoreReceipt()    
      } else {
        this.getReferralCode().then(code => {
          debugger
          this.sendReferralCode(code)
        })
        this.keyboardIsReady()
      }
      this.getAzureVoices()
      const myURL = new URL(window.location.href)
      if (myURL.pathname === '/shareButton') {
        const params = new URLSearchParams(window.location.search)
        const buttonId = params.get('id')
        if (buttonId) {
          this.receiveSharedButton(buttonId)
        }
      } else if (myURL.pathname === '/shareThread') {
        const params = new URLSearchParams(window.location.search)
        const threadId = params.get('id')
        const from = params.get('from')
        if (from && threadId) {
          this.receiveSharedThread(from, threadId)
        }
      }
    } else {
      if (this.profileSub) {
        this.profileSub.unsubscribe()
        this.profileSub = null
      }
    }
  }

  getAzureVoices = async () => {
    debugger
    const result = await this.getVoices()
    debugger
    this.azureVoices = result
  }

  getGoogleVoices = async () => {
    const result = await this.getVoices()
    this.googleVoices = result.voices.filter(x => x.name.indexOf("Neural2") > 0 || x.name.indexOf("Wavenet") > 0)
    return this.googleVoices
  }

  emailExists = async email => {
    email = email.trim().toLowerCase()
    const func = this.firebase.functions().httpsCallable('emailExists')
    const result = await func({email})
    return result.data
  }

  phoneNumberExists = async phoneNumber => {
    const func = this.firebase.functions().httpsCallable('phoneNumberExists')
    const result = await func({phoneNumber})
    return result.data
  }

  verifyInvite = async (email, verificationCode) => {
    const func = this.firebase.functions().httpsCallable('verifyInvite')
    const arg = { email, verificationCode: Number(verificationCode) }
    const result = await func(arg)
    consoleLog('verifyInvite', arg, result)
    return result.data
  }

  createAccount = async (email, password) => {
    const result = await this.firebase.auth().createUserWithEmailAndPassword(email, password)
    return result.user
  }

  createCustomerAccount = async (verification, form) => {
    consoleLog('createCustomerAccount', verification, form)
    const { name, email, phoneNumber, address, country, state, zip, password } = form
    let user
    if (!this.self) {
      user = await this.createAccount(email, password)
    } else {
      user = this.self
    }
    const func = this.firebase.functions().httpsCallable('setupCustomerAccount')
    const { verificationCode } = verification
    const arg = {
      invite: { verificationCode: parseInt(verificationCode), email },
      form: {
        name, email, phoneNumber, address, country, state, zip
      }
    }
    consoleLog(arg)
    debugger
    const result = await func(arg)
    consoleLog(result)
    debugger
    if (result.error) {
      throw new Error(result.error)
    }
  }    
  
  signIn = async (email, password) => {
    email = email.trim()
    password = password.trim()
    console.log("signin in")
    const result = await this.firebase.auth().signInWithEmailAndPassword(email, password)
    this.onAuthStateChanged(result.user)
    const creds = {
      type: 'login',
      email: email,
      password: password,
      phoneNumber: result.user.phoneNumber
    }
    //alert('login ' + JSON.stringify(creds))
    this.sendNativeMessage(creds)
  }

  isSignedInAnonymously = () => {
    const user = this.firebase.auth().currentUser
    const result =  user && user.isAnonymous
    console.log("isAnonymous", result)
    return result
  }

  signInAnonymously = async () => {
    return await this.firebase.auth().signInAnonymously()
  }

  signInWithPhoneNumber = async (phoneNumber, recaptcha) => {
    const { exists }  = await this.phoneNumberExists(phoneNumber)
    if (!exists && await this.isSignedInAnonymously()) {
      return await this.firebase.auth().currentUser.linkWithPhoneNumber(phoneNumber, recaptcha)
    }
    return await this.firebase.auth().signInWithPhoneNumber(phoneNumber, recaptcha)
  }

  updatePassword = async newPassword => {
    await this.firebase.auth().currentUser.updatePassword(newPassword)
  }

  resetPassword = async email => {
    await this.firebase.auth().sendPasswordResetEmail(email);
  }

  signOut = async () => {
    this.signUpDisplayName = null;
    this.creds = null
    this.sendNativeMessage({
      type: 'signOut'
    })
    if (window.webkit && window.webkit.messageHandlers) {
      const msg = {
        type: 'signOut',
      }
      window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
    }
    await this.firebase.auth().signOut()
  }

  likeCache = {}

  likes = async (id) => {
    const docId = id + '-' + this.self.uid
    if (this.likeCache[docId] === undefined) {
      const snap = await this.firebase.firestore().collection('Liked').doc(docId).get()
      this.likeCache[docId] = snap.exists
    }
    return this.likeCache[docId]
  }

  saveComment = async (id, msg) => {
    const func = this.firebase.functions().httpsCallable('saveComment')
    const { ts, from, to, text } = msg
    const response = await func({ id, msg: { ts, from, to, text } })
  }

  deleteComment = async (id, msg) => {
    const ref = this.firebase.firestore().collection('MyMoves').doc(id).collection('Comments').doc(String(msg.ts))
    await ref.delete()
  }
  
  observeComments = sharedMove => {
    const q = this.firebase.firestore().collection('MyMoves').doc(sharedMove.id).collection('Comments')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
	const { type, doc } = change
	const data = doc.data()
	return {
	  type,
	  comment: data
	}
      })
    }))
  }

  resolveUserAsync = async uid => {
    const user = this.resolveUser(uid)
    if (user) return user
    const snap = await this.firebase.firestore().collection('Users').doc(uid).get()
    return snap.data()
  }

  observeMoveStatus = move => {
    const id = this.getMoveId(move)
    return doc(this.firebase.firestore().collection('MoveStatus').doc(id)).pipe(map(snap => snap.data()))
  }

  observeMove = id => {
    return doc(this.firebase.firestore().collection('MyMoves').doc(id)).pipe(flatMap(snap => {
      const data = snap.data()
      if (!data) {
        return null
      }
      return from(this.resolveUserAsync(data.uid)).pipe(map(user => {
        const sharedMove = {
	  id,
	  move: createMove(data.move),
	  timestamp: data.lastUpdated,
	  user,
          likes: data.likes || 0,
          shares: data.shares || 0,
          comments: data.comments || 0
        }
        return sharedMove
      }))
    }))
  }

  saveReaction = async (id, ts, emoji) => {
    const func = this.firebase.functions().httpsCallable('saveReaction')
    const response = await func({
      id, ts, emoji
    })
  }

  like = async id => {
    const func = this.firebase.functions().httpsCallable('like')
    const response = await func({id})
    const { likes } = response.data
    const docId = id + '-' + this.self.uid
    this.likeCache[docId] = likes
    return likes
  }

  follow = async uid => {
    const func = this.firebase.functions().httpsCallable('follow')
    const response = await func({uid})
    return response.data
  }
  
  unfollow = async uid => {
    const func = this.firebase.functions().httpsCallable('unfollow')
    const response = await func({uid})
    return response.data
  }

  observeFollowing = () => {
    const q = this.firebase.firestore().collection('Users').doc(this.self.uid).collection('Following')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
	const { type, doc } = change
	const data = doc.data()
	data.id = doc.id
	return {
	  type,
	  user: data
	}
      })
    }))
  }

  observeFollowers = () => {
    const q = this.firebase.firestore().collection('Users').doc(this.self.uid).collection('Followers')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
	const { type, doc } = change
	const data = doc.data()
	data.id = doc.id
	return {
	  type,
	  user: data
	}
      })
    }))
  }

  userCache = {}

  observeUsers = (selection) =>  {
    let c = this.firebase.firestore().collection('Users')
    return collectionChanges(c).pipe(flatMap(changes => {
      return changes.map(change => {
	const { type, doc } = change
	const data = doc.data()
        if (type === 'removed') {
          delete this.userCache[doc.id]
        } else {
          this.userCache[doc.id] = data
        }
	data.id = doc.id
	return {
	  type,
	  user: data
	}
      })
    }))
  }

  observeContactOnline = () => {
    return from([])
  }

  resolveUser = uid => {
    if (uid === this.self.uid) {
      this.profile
    }
    return this.userCache[uid]
  }
  
  observeProfile = () => {
    return of(this.self)
  }

  observeMyMovesRaw = (timestamp) => {
    const c = this.firebase.firestore().collection('MyMoves')
    const q = c.where('uid', '==', this.self.uid).where('lastUpdated', '>=', timestamp).orderBy('lastUpdated', 'asc')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const id = doc.id
        const data = doc.data()
        data.id = id
        return {
	  type,
	  data
        }
        
      })
    }))
  }
  
  observeHome = (timestamp) => {
    return this.observeForYou(timestamp)
  }

  observeForYou = (timestamp) => {
    const q = this.firebase.firestore().collection('ForYou').where('you', '==', this.self.uid).orderBy('posted', 'desc')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
	const { type, doc } = change
	const data = doc.data()
	const { move, id, uid, displayName, username, timestamp, posted } = data
	return {
	  type,
	  sharedMove: {
            id: id,
	    user: {
              uid, displayName, username
            },
	    timestamp,
	    move: createMove(move),
            likes: data.likes || 0,
            shares: data.shares || 0,
            comments: data.comments || 0,
            posted: data.posted || 0
	  }
	}
      })
    })).pipe(catchError(err => {
      console.error(err)
      return from([])
    }))
  }

  observeChatSettings = () => {
    return this.observeSelf().pipe(flatMap(self => {
      const ref = this.firebase.firestore().collection("ChatSettings").doc(self.uid)
      return doc(ref).pipe(map(snap => {
          if (snap.exists) {
            return snap.data()
          } 
          return {
            model: "gpt-3.5-turbo"
          }
        }))
    }))
  }

  observeSettings = () => {
    return this.observeSelf().pipe(flatMap(self => {
      debugger
      if (self) {
        const ref = this.firebase.firestore().collection("KeyboardSettings").doc(self.uid)
        return doc(ref).pipe(map(snap => {
          if (snap.exists) {
            return snap.data()
          } 
          return {
            autoCorrection: true,
            hapticFeedback: true,
            audioFeedback: false
          }
        }))
      }
      return from([])
    }))
  }

  saveChatSettings = async settings => {
    const { 
      model
    } = settings
    const ref = this.firebase.firestore().collection("ChatSettings").doc(this.self.uid)
    const updates = {
      model
    }
    return await ref.set(updates)
  }

  getKeyboardSettings = async () => {
    const ref = this.firebase.firestore().collection("KeyboardSettings").doc(this.self.uid)
    const snap = await ref.get()
    return snap.exists ? snap.data : {
      autoCorrection: true,
      hapticFeedback: true,
      audioFeedback: false
    }
  }

  saveSettings = async settings => {
    const { 
      autoCorrection,
      hapticFeedback,
      audioFeedback
    } = settings
    const ref = this.firebase.firestore().collection("KeyboardSettings").doc(this.self.uid)
    const updates = {
      autoCorrection,
      hapticFeedback,
      audioFeedback
    }
    this.sendKeyboardSettings(updates)
    return await ref.set(updates)
  }

  getProfile = () => {
    const { photoURL, displayName } = this.self
    return {
      bio: '',
      profileImage: photoURL,
      homepage: '',
      username: displayName,
      displayName: '',
    }
  }
  getAnalytics = () => {
    return {
      followers: 0,
      following: 0,
      likes: 0,
      shared: 0
    }
  }

  uploadFileImpl = async (type, channel, file, progress) => {
    const filename = uuidv4() + file.name
    const targetRef = this.firebase.storage().ref(type).child(channel).child(filename)
    if (!file.type.startsWith('video/')) {
      // just upload directly
      const uploadTask = targetRef.put(file)
      if (progress) {
        uploadTask.on('state_changed', snap => {
          const percent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
          debugLog("progress:", percent)
          if (progress) {
            progress(percent);
          }
        })
      }
      await uploadTask
      return targetRef
    }
    const videoDimensions = await this.getVideoDimensions(file)
    // invoke support for transcoding video
    const func = this.firebase.functions().httpsCallable('createUpload')
    const result = await func({
      contentType: file.type,
      type: type,
      channel: channel,
      filename: filename,
      videoDimensions
    })
    const { uploadId, path } = result.data
    const ref = this.firebase.storage().ref(path)
    const uploadTask = ref.put(file)
    if (progress) {
      uploadTask.on('state_changed', snap => {
        const percent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
        debugLog("progress:", percent)
        if (progress) {
          progress(percent);
        }
      })
    }
    await uploadTask
    const p = new Promise((resolve, reject) => {
      const unsubscribe = this.firebase.firestore().collection('Uploads').doc(uploadId).onSnapshot(snap => {
        const data = snap.data()
        if (data) {
          switch (data.status) {
            case 'completed':
              unsubscribe()
              if (progress) progress(100)
              resolve()
              break
            case 'failed':
              unsubscribe()
              if (progress) progress(100)
              reject(new Error(data.failureReason))
              break
          }
        }
      })
    })
    await p
    return targetRef
  }

  getVideoDimensions = async file => {
    const url = URL.createObjectURL(file)
    const video = document.createElement('video')
    video.src = url
    const videoDimensions = await new Promise(resolve => {
      video.onloadedmetadata = e => {
        resolve({
          height: video.height,
          width: video.width
        })
      }
      video.load()
    })
    return await videoDimensions
  }

  uploadFileToChannel = async (channel, file, progress) => {
    return await this.uploadFileImpl('Channels', channel, file, progress)
  }
  
  uploadFile = async (file, progress) => {
    return await this.uploadFileImpl('Files', this.self.uid, file, progress)
  }

  uploadProfileImage = (file, progress) => {
    const ref = this.firebase.storage().ref("ProfileImages").child(this.self.uid);
    return new Promise((resolve, reject) => {
      const uploadTask = ref.put(file)
      if (progress) {
        progress(0)
      }
      uploadTask.on("state_changed", snap => {
        //debugLog("state_changed", snap);
        const percent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
        if (progress) {
          progress(percent)
        }
      }, reject, () => {
        return resolve(ref.getDownloadURL());
      })
    })
  }

  lastViewed = 0
  lastViewedSubject = new Subject()

  getLastViewed = () => this.lastViewed

  observeLastViewed = () => {
    return concat(of(this.lastViewed), this.lastViewedSubject)
  }

  markViewed = async move => {
    if (move.posted > this.lastViewed) {
      await updateProfile({
        lastViewed: move.posted
      })
    }
  }

  saveProfile = async updates => {
    console.log('saveProfile', updates)
    debugger
    const profileUpdates = {}
    for (const field of  ['bio', 'username', 'displayName', 'profileImage']) {
      profileUpdates[field] = updates[field]
    }
    const userUpdates = {}
    for (const field of ['email', 'phoneNumber', 'password']) {
      profileUpdates[field] = updates[field] || undefined
    }
    if (profileUpdates.phoneNumber) {
      const converted = phone(profileUpdates.phoneNumber);
      profileUpdates.phoneNumber = converted[0]
    }
    const func = this.firebase.functions().httpsCallable('saveProfile')
    const result = await func(profileUpdates)
    return result.data
  }

  //@TODO remove this
  updateProfile = async updates => {
    const ref = this.firebase.firestore().collection('Users').doc(this.self.uid)
    await ref.set(updates, { merge: true })
  }

  openWindow = (url, arg) => {
    if (this.isNative()) {
      const msg = {
        type: 'openURL',
        openURL: url
      }
      window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
      return
    }
    console.log("window open", url)
    return window.open(url, arg)
  }


  getChannel = uid => {
    const ids = [uid, this.self.uid]
    ids.sort()
    return ids.join('-')
  }

  observeDM = uid => {
    const id = this.getChannel(uid)
    const q = this.firebase.firestore().collection('DMs').where('to', '==', id).orderBy('ts', 'desc')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const type = change.type
        const doc = change.doc
        const data = doc.data()
        data.id = doc.id
        return {
          type,
          comment: data
        }
      })
    }))
  }

  saveDM = async (uid, message) => {
    const func = this.firebase.functions().httpsCallable('saveDM')
    const result = await func({to: uid, msg: message})
    return message.id = result.data.id
  }

  deleteDM = async (uid, message) => {
    const func = this.firebase.functions().httpsCallable('deleteDM')
    const result = await func({id: message.id})
  }

  reactToDM = async (uid, message, emoji) => {
    const func = this.firebase.functions().httpsCallable('reactToDM')
    const response = await func({
      to: uid, id: message.id, emoji
    })
  }


  reqId = 0
  ops = {}

  purchaseWordPack = async (wordPack, status) => {
    if (this.isNative()) {
      const reqId = String(++this.reqId)
      const msg = {
        type: 'purchase',
        productId: wordPack.productId,
        reqId: reqId,
        uid: this.self.uid
      }
      console.log(msg)
      status("purchase")
      const result = await new Promise((resolve, reject) => {
        this.ops[reqId] = {
          resolve, reject
        }
        window.webkit.messageHandlers.interOp.postMessage(JSON.stringify(msg))
      })
      const { transactionId, purchased, jws } = result
      console.log("got purchase result", result)
      if (transactionId) {
        status("verify")
        const { verified } = await this.verifyAppleReceipt(transactionId, jws)
        console.log("purchase completed successfully")
        status("complete")
        return verified
      } else {
        status("canceled")
      }
      console.log("purchase completed unsuccessfully")
      return false
    } else {
      status("purchase")
      await delay(2)
      status("verify")
      await delay(2)
      if (false && Math.random() > 0) {
        status("canceled")
      } else {
        status("complete")
      }
      return false
    }
  }

  observeWordPacks = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('Products')
    const filt = change => {
      const { wordPack } = change
      if (wordPack.isDraft) {
        return this.isAdmin || this.isReview
      }
      return true
    }
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          wordPack: data,
          type
        }
      })
    }), filter(filt))
  }

  deleteButton = async (button) => {
    console.log(button)
    const { id, buttonType } = button
    const func = this.firebase.functions().httpsCallable('deleteButton')
    const result = await func({id, buttonType})
    return result.data
  }

  shareButton = async (button) => {
    const { id, category, instruction } = button
    const shareData = {
      title: category + ' | ' + instruction,
      text: category + ' | ' + instruction,
      url: window.location.origin + `/shareButton?instruction=${encodeURIComponent(instruction)}&category=${encodeURIComponent(category)}&id=${id}`
    }
    console.log("shareData", shareData)
    try {
      result = await navigator.share(shareData)
      console.log(result)
    } catch (err) {
      console.error(err)
    }
  }

  observeAllWritingButtons = () => {
    return mergeN(this.observeWritingButtons(), this.observeMyWritingButtons())
  }
  
  observeWritingButtons = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('WriteButtons')
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        data.buttonType = 'create'
        data.isSystem = true
        return {
          button: data,
          type
        }
      })
    }))
  }

  observeMyWritingButtons = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('MyWriteButtons').where('uid', '==', this.self.uid)
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        data.buttonType = 'create'
        return {
          button: data,
          type
        }
      })
    }))
  }

  observeAllButtons = () => {
    return mergeN(this.observeButtons(), this.observeMyButtons())
  }

  observeButtonUsage = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('ButtonUsage').doc(this.self.uid)
    return docData(ref).pipe(map(data => {
      return data || {}
    }))
  }

  observeMyButtons = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('MyButtons').where('uid', '==', this.self.uid)
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.buttonType = 'edit'
        data.id = doc.id
        return {
          button: data,
          type
        }
      })
    }))
  }

  observeButtons = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('Buttons')
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        data.buttonType = 'edit'
        data.isSystem = true
        return {
          button: data,
          type
        }
      })
    }))
  }

  observeAvailableWords = () => {
    let words = 0
    const purchased = this.observePurchases().pipe(map(change => {
      switch (change.type) {
        case 'added':
          words += change.wordPack.wordCount
          break
        case 'removed':
          words -= change.wordPack.wordCount
      }
      return words
    }))
    const used = this.observeUsage().pipe(map(usage => usage.words))
    return concat(of(words),
                  combineLatest([purchased, used]).pipe(map(([purchased, used]) => {
                    return purchased - used
                  })))
  }

  observePurchases = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('Purchases').where('uid', '==', this.self.uid)
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          wordPack: data,
          type
        }
      })
    }),catchError(err => {
      debugger
      return from([])
    }))
  }

  saveInstruction = async (instruction, buttonType) => {
    const func = this.firebase.functions().httpsCallable('saveInstruction')
    const result = await func({instruction, buttonType})
    return result.data
  }

  observeUsage = () => {
    const db = this.firebase.firestore()
    const ref = db.collection('Usage').doc(this.self.uid)
    return doc(ref).pipe(map(snap => {
      return {
        words: snap && snap.data() ? Math.round((snap.data().tokens || 0) * 0.75) : 0
      }
    }))
  }

  fixGrammar = async (input) => {
    const { output } =  await this.edit("clean up grammar", input)
    return output || text
  }

  simplify = async (input, type) => {
    return await this.edit(`"${input}"`, 'correct wording, capitalization, and punctuation only. Don\'t respond.')
  }

  autocompleteTemplate = async (template, variable, noCache) => {
    const key = `(${template},${variable})`
    if (!noCache) {
      const value = localStorage.getItem(key)
      if (value) {
        return { completions: JSON.parse(value) }
      }
    }
    try {
      const func = this.getFunc('autocompleteTemplate')
      const result = await func({template, variable})
      const { completions, error } =  result.data
      if (!error) {
        localStorage.setItem(key, JSON.stringify(completions))
      }
      return result.data
    } catch (err) {
      console.error(err)
    }
    return { completions: [] }
  }

  resolveReferralCode = async code => {
    const func = this.firebase.functions().httpsCallable('resolveReferralCode')
    const result = await func({code})
    return result.data
  }

  edit = async (instruction, input, buttonId, temperature) => {
    console.log('edit', instruction, 'input', input, 'temp', temperature)
    const data = {instruction, input, version: 'turbo', buttonId, temperature}
    if (false) {
      const server = "http://192.168.1.106:8080"
      const url = `${server}/edit?input=${encodeURIComponent(input)}&instruction=${encodeURIComponent(instruction)}`
      const result = await axios.get(url)
      return result.data
    } else if (false) {
      const server = "http://192.168.1.106:8082"
      const url = `${server}/edit`
      const token = await this.getIdToken()
      const result = await axios.post(url, data, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      return result.data
    }
    const func = this.getFunc('edit')
    const result = await func(data)
    const { output, error } =  result.data
    debugger
    return result.data
  }

  getLang = async input => {
    let server = "https://autocomplete-o4pon4eraa-uc.a.run.app"
    //server = "http://192.168.1.106:8080"
    const url = `${server}/lang?input=${encodeURIComponent(input)}`
    const response = await axios.get(url)
    let { lang } = response.data
    return  resolveDialect(lang)
  }

  cancelSpeak = () => {
    if (this.currentSource) {
      this.currentSource.stop()
      this.currentSource = null
      return
    }
    speechSynthesis.cancel()
  }

  getVoices = async () => {
    const token = await this.getToken()
    const response = await axios.post(`${server}/listVoices`, {})
    return response.data
  }

  getVoiceLangs = () => {
    const Langs = {}
    if (this.googleVoices) {
      this.googleVoices.forEach(voice => {
        voice.languageCodes.forEach(lang => {
          Langs[lang] = voice
        })
      })
    } else if (this.azureVoices) {
      this.azureVoices.forEach(voice => {
        const lang = voice.Locale
        Langs[lang] = voice
      })
    } else {
      const voices = speechSynthesis.getVoices()
      voices.forEach(voice => {
        let arr = Langs[voice]
        if (!arr) {
          arr = [voice]
          Langs[voice.lang] = arr
        } else {
          arr.push(voice)
        }
      })
    }
    return Langs
  }

  resetAudioSource = () => {
    if (!this.currentAudioContext) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)()
      this.currentAudioContext = audioContext
      unmute(this.currentAudioContext)
    }
    if (this.currentSource) {
      try {
        this.currentSource.stop()
      } catch (ignored) {
      }
    }
    const source = this.currentAudioContext.createBufferSource()
    source.connect(this.currentAudioContext.destination)
    this.currentSource = source
  }

  googleSpeak = async (text, lang) => {
    console.log("speak", lang)
    const self = this
    const token = await this.getToken()
    const voices = this.googleVoices.filter(x => {
      return x.languageCodes.find(code => code === lang)
    })
    const voice = randomElement(voices)
    const response = await axios.post(`${server}/speak`, { text, lang, voice: voice.name }, {
      responseType: "arraybuffer",
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    const audioBlob = response.data
    console.log("audio length", audioBlob.byteLength)
    debugger
    // Create a new AudioContext
    // Set the buffer property of the AudioBufferSourceNode to the decoded data
    return new Promise((resolve, reject) => {
      // Decode the audio data from the blob
      if (self.currentSource) {
        self.currentSource.onended = resolve
        self.currentAudioContext.decodeAudioData(audioBlob, function(decodedData) {
          if (self.currentSource) {
            const source = self.currentSource
            source.buffer = decodedData;
            source.start(0)
          } else {
            resolve()
          }
        })
      } else {
        resolve()
      }
    })
    
  }

  azureSpeak = async (text, lang) => {
    console.log("speak", lang)
    const self = this
    const token = await this.getToken()
    const voices = this.azureVoices.filter(x => {
      return x.Locale === lang
    })
    const voice = randomElement(voices)
    const response = await axios.post(`${server}/speak`, { text, lang, voice: voice.ShortName }, {
      responseType: "arraybuffer",
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    const audioBlob = response.data
    console.log("audio length", audioBlob.byteLength)
    debugger
    // Create a new AudioContext
    // Set the buffer property of the AudioBufferSourceNode to the decoded data
    return new Promise((resolve, reject) => {
      // Decode the audio data from the blob
      if (self.currentSource) {
        self.currentSource.onended = resolve
        self.currentAudioContext.decodeAudioData(audioBlob, function(decodedData) {
          if (self.currentSource) {
            const source = self.currentSource
            source.buffer = decodedData;
            source.start(0)
          } else {
            resolve()
          }
        })
      } else {
        resolve()
      }
    })
    
  }
  

  witSpeak = async (text, lang) => {
    debugger
    const response = await axios.post('https://api.wit.ai/synthesize',
                                      {
                                        q: text,
                                        voice: "Rebecca"
                                      },
                                      {
                                        responseType: 'arraybuffer',
                                        headers: {
                                          Authorization:"Bearer YUCQVY2MIS5V2UR6224QTHJOF54DASUD",
                                          Accept: "audio/wav"
                                        }
                                      })
    const audioBlob = response.data
    // Create a new AudioContext
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    
    // Decode the audio data from the blob
    audioContext.decodeAudioData(audioBlob, function(decodedData) {
      // Create a new AudioBufferSourceNode
      const source = audioContext.createBufferSource();
      
      // Set the buffer property of the AudioBufferSourceNode to the decoded data
      source.buffer = decodedData;
      
      // Connect the AudioBufferSourceNode to the destination (e.g., speakers)
      source.connect(audioContext.destination);
      
      // Start playing the audio
      source.start();
    })
    
  }

  speak = (text, lang) => {
    if (true) {
      return this.azureSpeak(text, lang)
    }
    if (true) {
      return this.googleSpeak(text, lang)
    }
    if (false && lang === 'en-US') {
      return this.witSpeak(text)
    }
    speechSynthesis.cancel()
    return new Promise(resolve => {
      const utterThis = new SpeechSynthesisUtterance(text)
      console.log("setting utterance lang", lang)
      const dialect = resolveDialect(lang)
      if (dialect) {
        utterThis.lang = dialect.iso
      }
      const voices = speechSynthesis.getVoices().filter(voice => voice.lang === utterThis.lang)
      console.log(voices)
      debugger
      let voice
      if (lang == 'en-US') {
        voice = voices.find(voice => voice.name === "Samantha")
      } else {
        voice = voices[0]
      }
      if (!voice) {
        const lang = utterThis.lang.split('-')[0] + '-'
        voice = speechSynthesis.getVoices().find(voice => voice.lang.startsWith(lang))
      }
      utterThis.voice = voice
      this.utterance = utterThis
      utterThis.onend = resolve
      speechSynthesis.speak(utterThis)
    })
  }

  shouldUseWhisper = () => !!WHISPER 

  getVoiceRecognizer = () => {
    //return new VoiceRecognizer()
    if (!this.voiceRecognizer) {
      this.voiceRecognizer = this.shouldUseWhisper() ?
        new WhisperVoiceRecognizer() : 
        new SystemVoiceRecognizer(this.autocorrect)
    }
    return this.voiceRecognizer
  }

  observeRooms = () => {
    const q = this.firebase.firestore().collection('Rooms')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
	const { type, doc } = change
	const data = doc.data()
	data.id = doc.id
	return {
	  type,
	  user: data
	}
      })
    }))
  }

  initPeer = peer => {
    peer.observeAudio().subscribe(buffer => {
    })
    this.peers[peer.id] = peer
  }

  peers = {}

  openVoiceChat = async user => {
    if (!this.peers[user.uid]) {
      const peer = new Peer(user)
      this.peers[user.uid] = 
      this.p2p.call(user.uid).then(pc => {
        peer.initPC(pc)
      })
    }
    startMicInput()
  }

  startMicInput = () => {
    if (!this.micInput) {
      this.micInput = this.observeMicInput().subscribe(async blob => {
        console.log("got mic input", blob)
        for (const id in this.peers) {
          const peer = this.peers[id]
          peer.sendAudio(audio)
        }
      })
    }
  }

  autocorrect = async opts => {
    const { input, lang, timestamp } = opts
    const func = this.getFunc("autocorrect")
    const response = await func({input, lang, timestamp})
    return response.data
  }

  autocorrectOld = async (input, lang) => {
    let server = "https://autocomplete-o4pon4eraa-uc.a.run.app"
    //server =' http://localhost:8080'
    let prefix = ''
    if (lang) {
      const seg = new Intl.Segmenter(lang, { granularity: "sentence" })
      const segs = Array.from(seg.segment(input))
      const i = segs.length - 2
      if (i > 0) {
        const segment = segs[i]
        prefix = input.substring(0, segment.index)
        input = input.substring(segment.index)
      }
    }
    const response = await axios.get(`${server}/autocorrect/?input=${encodeURIComponent(input)}`)
    const result = response.data
    console.log(result)
    return prefix + result.output
  }

  autocomplete = async input => {
    let server = "https://autocomplete-o4pon4eraa-uc.a.run.app"
    //server = "http://localhost:8080"
    const url = `${server}/autocomplete?input=${encodeURIComponent(input)}`
    const response = await axios.get(url)
    return response.data
  }

  getSafeAreaInsetTop = () => {
    const result =
          getComputedStyle(document.documentElement).getPropertyValue("--sat")
    return parseFloat(result.substring(0,
                                       result.length-2))
  }

  getSafeAreaInsetBottom = () => {
    const result =
          getComputedStyle(document.documentElement).getPropertyValue("--sab")
    return parseFloat(result.substring(0,
                                       result.length-2))
  }

  getSafeAreaInsetLeft = () => {
    const result =
          getComputedStyle(document.documentElement).getPropertyValue("--sal")
    return parseFloat(result.substring(0,
                                       result.length-2))
  }

  receiveSharedButton = async (buttonId) => {
    debugger
    const func = this.firebase.functions().httpsCallable('receiveSharedButton')
    const data = {buttonId}    
    await func(data)
  }

  deleteAccount = async () => {
    const func = this.firebase.functions().httpsCallable('deleteAccount')
    await func()
    this.signOut()
  }

  updatePhoneNumber = async (phoneNumber) => {
    await delay(5.0)
  }

  openAIStatusSubject = new Subject()

  checkOpenAIStatus = async () => {
    const url = 'https://status.openai.com/api/v2/status.json'
    const response = await axios.get(url)
    const { status } = response.data
    console.log("openAI status", status)
    if (status) {
      const { indicator, description } = status
      if (!this.openAIStatus ||
          this.openAIStatus.status != status ||
          this.openAIStatus.description != description) {
        this.openAIStatus = status
        this.openAIStatusSubject.next(status)
      }
    }
  }
  
  observeOpenAIStatus = () => {
    if (!this.statusInterval) {
      this.statusInterval = setInterval(this.checkOpenAIStatus, 30000)
    }
    this.checkOpenAIStatus()
    return this.openAIStatusSubject
  }

  // stripe related

  cancelCustomerSetupIntent = async (setupIntent) => {
    const id = setupIntent.id
    const func = this.firebase.functions().httpsCallable('cancelSetupIntent')
    const arg = {id}
    const result = await func(arg)
    return result.data
  }
  
  createCustomerSetupIntent = async () => {
    const func = this.firebase.functions().httpsCallable('createSetupIntent')
    const arg = {}
    const result = await func(arg)
    return result.data
  }

  removeCustomerPaymentMethod = async paymentMethod => {
    const func = this.firebase.functions().httpsCallable('removeCustomerPaymentMethod')
    const arg = {id: paymentMethod.id}
    const result = await func(arg)
    return result.data
  }

  createCustomerPaymentIntent = async productId => {
    debugger
    const func = this.firebase.functions().httpsCallable('createPaymentIntent')
    const arg = { productId }
    const result = await func(arg)
    return result.data
  }

  getPaymentMethod = async id => {
    const func = this.firebase.functions().httpsCallable('getPaymentMethod')
    const arg = {id}
    const result = await func(arg)
    return result.data
  }

  getPaymentMethods = async id => {
    const func = this.firebase.functions().httpsCallable('getPaymentMethods')
    const arg = {}
    const result = await func(arg)
    return result.data
  }
  
  selectCustomerPaymentMethod = async paymentMethod => {
    const func = this.firebase.functions().httpsCallable('selectCustomerPaymentMethod')
    const arg = { paymentMethodId: paymentMethod.id }
    const result = await func(arg)
    return result.data
  }
  observeCustomer = () => {
    return doc(this.firebase.firestore().collection('Customers').doc(this.self.uid)).pipe(map(snap => {
      return snap.exists ? snap.data() : null
    }))
  }

  observePaymentMethods = () => {
    return this.observeCustomer().pipe(map(customer => {
      debugger
      if (customer) {
        let { paymentMethods, selectedPaymentMethod } = customer
        if (paymentMethods && selectedPaymentMethod) {
          selectedPaymentMethod = paymentMethods.find(x => x.id === selectedPaymentMethod)
        }
        return { paymentMethods, selectedPaymentMethod }
      }
      return {}
    }))
  }

  reportProgress = async state => {
    if (!this.isNative()) {
      return
    }
    const db = this.firebase.firestore()
    if (!this.installation) {
      const ref = db.collection("Installation").doc(this.self.uid)
      const snap = await ref.get()
      this.installation = snap.exists ? snap.data() : {}
    }
    if (this.installation[state]) {
      return
    }
    this.installation[state] = true
    const ref = db.collection("Installation").doc(this.self.uid)
    const updates = {}
    updates[state] = true
    await ref.set(updates, { merge: true })
  }

  handleReferral = uid => {
  }

  handleInvitation = link => {
  }

  getAllButtons = async () => {
    const func = this.firebase.functions().httpsCallable('getAllButtons')
    const result = await func({})
    return result.data
  }

  getReferralCode = async () => {
    console.log('getReferralCode', this.referralCode)
    if (!this.referralCode) {
      const func = this.firebase.functions().httpsCallable('getReferralCode')
      const result = await func({})
      const data = result.data
      console.log('getReferralCode result', data)
      const { code } = data
      this.referralCode = code || null
    }
    return this.referralCode
  }

  getApiKey = async () => {
    if (!this.apiKey) {
      const func = this.getFunc('getApiKey')
      const result = await func({})
      const data = result.data
      console.log('getApiKey result', data)
      const { apiKey } = data
      this.apiKey = apiKey || null
    }
    return this.apiKey
  }

  getInviteCode = () => {
    return InviteCode
  }

  redirectToAppStore() {
    this.openWindow("https://apps.apple.com/us/app/intellikey/id1667188645")
  }

  checkForServerConnectivity = async () => {
    let result = false
    console.log("origin", window.location.origin)
    if (window.location.host.startsWith("localhost")) {
      result = Math.random () > 0.5 ? true : false
    } else {
      const file = window.location.origin + '/index.html'
      const  randomNum = Math.round(Math.random() * 10000)
      try {
        const response = await axios.head(file + "?rand=" + randomNum)
        console.log("head result", response.status)
        result = true
        const now = Date.now()
        if (this.lastServerReachableTime > 0 &&
            now - this.lastServerReachableTime > 60000) {
          //window.location.reload()
        }          
        this.lastServerReachableTime = now
      } catch (err) {
        console.log(err.message)
      }
    }
    this.serverReachable = result
    this.serverConnectivitySubject.next(result)
  }

  doCheckForServerConnectivity = async () => {
    this.serverReachable = result
    this.serverConnectivitySubject.next(result)
  }
  lastServerReachableTime = 0
  serverConnectivitySubject = new Subject()
  observeServerConnectivity = () => {
    if (true) {
      return of(true)
    }
    if (!this.serverConnectivityTimer) {
      let interval = 15000
      if (window.location.host.startsWith("localhost")) {
        interval = 3000
      }
      this.serverConnectivityTimer = setInterval(this.checkForServerConnectivity, interval)
      this.checkForServerConnectivity()
    }
    if (this.serverReachable !== undefined) {
      return concat(of(this.serverReachable), this.serverConnectivitySubject)
    }
    return this.serverConnectivitySubject
  }

  getMessagesRef = () => this.firebase.firestore().collection('ChatGPT').doc(this.self.uid).collection('messages')
  getThreadsRef = () => this.firebase.firestore().collection('ChatGPT').doc(this.self.uid).collection('threads')

  searchChatMessages = async (q, topic, model) => {
    if (!q && !topic || topic === 'new-thread') {
      return {
        results: [],
        page: 0,
        out_of: 0
      }
    }
    const func = await this.getFunc("searchChatMessages")
    const response = await func({q, topic, model})
    const { results } = response.data
    results.forEach(result => {
      if (Array.isArray(result.reaction)) {
        result.reaction = result.reaction.join('')
      }
    })
    return response.data
  }

  searchChatThreads = async q => {
    const func = await this.getFunc("searchChatThreads")
    const response = await func({q})
    return response.data
  }

  observeHallucinations = () => {
    const db = this.firebase.firestore()
    const c = db.collection('Hallucinations')
    const q = c.orderBy('when', 'desc').limit(15)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.flatMap(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return [
          {
            type,
            message: {
              from: this.self.uid,
              id: data.id,
              ts: data.when,
              text: data.topic
            },
          },
          {
            type,
            message: {
              id: data.id + '-reply',
              inReplyTo: data.id,
              ts: data.when + 1,
              text: data.question,
              topic: topic
            }
          }
        ]
      })
    }))
  }

  observeChatMessages = (model) => {
    let q = this.getMessagesRef()
    if (model) q = q.where('model', '==', model)
    q = q.orderBy('ts', 'desc').limit(15)
    let ob = collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        if (Array.isArray(data.reaction)) {
          data.reaction = data.reaction.join('')
        } 
        return {
          type,
          message: data
        }
      })
    }))
    return ob
  }

  getThreadsHistory = async (model, earliest, limit) => {
    let q = this.getThreadsRef()
    q = q.where("model", '==', model)
    q = q.orderBy('lastUpdated', 'desc').where("lastUpdated", '<', earliest).limit(limit)
    const { docs } = await q.get()
    return docs.map(doc  => {
      const data = doc.data()
      data.id = doc.id
      return data
    })
  }
  
  observeChatThreads = (model) => {
    let q = this.getThreadsRef()
    q = q.where('model', '==', model)
    q = q.orderBy('lastUpdated', 'desc').limit(50)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          type,
          thread: data
        }
      })
    }))
  }

  waitForStripePurchase = paymentIntent => {
    const db = this.firebase.firestore()
    const c = db.collection('Purchases')
    const q = c.where('uid', '==', this.self.uid).where('stripeId', '==', paymentIntent.id)
    return new Promise(resolve => {
      const ob = collectionChanges(q).pipe(flatMap(changes => {
        return changes.map(change => change.doc)
      }))
      const sub = ob.subscribe(snap => {
        if (snap.exists) {
          debugger
          sub.unsubscribe()
          resolve()
        }
      })
    })
  }
  
  observeSubthreads = (topicId) => {
    const q = this.getThreadsRef().where('subtopic', '==', topicId).orderBy('lastUpdated', 'desc').limit(50)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          type,
          thread: data
        }
      })
    }))
  }
  
  getHistory = async (model, ts, limit) => {
    let q = this.getMessagesRef()
    q = q.where('model', '==', model)
    q = q.orderBy("ts", "desc").where("ts", "<", ts).limit(limit*2)
    const { docs } = await q.get()
    return docs.map(snap => {
      const data = snap.data()
      data.id = snap.id
      if (Array.isArray(data.reaction)) {
        data.reaction = data.reaction.join('')
      }
      return data
    })
  }

  getToken = () => this.self.getIdToken(false)

  shouldTraceChatGPT = () => {
    return window.location.hostname.startsWith('localhost')
  }

  getFunc = name => {
    if (true) {
      return async (data) => {
        const token = await this.getToken()
        const url = `${server}/${name}`
        console.log('name', data)
        const response = await axios.post(url, data, {
          headers: {
            Authorization: 'Bearer '  + token
          }
        })
        console.log("result", name, response.data)
        return response
      }
    }
    return this.firebase.functions().httpsCallable(name)
  }

  deleteChatMessage = async id => {
    const func = this.getFunc('deleteChatMessage')
    const response = await func({id})
    return response.data
  }

  streamChat = async (msg, isNewTopic, opts) => {
    const token = await this.getToken()
    const url = `${server}/streamChat`
    const dup = clone(msg)
    dup.thread = dup.topic
    delete dup.topic
    dup.utcOffset = this.utcOffset
    dup.isNewTopic = isNewTopic
    dup.noMarkdown = true;
    dup.model = opts.model
    let isBaseModel = false
    switch (opts.model) {
      case 'davinci':
      case 'llama-2-70b':
        isBaseModel = true
    }
    dup.hallucinate = this.isAskAppOnly() && !isBaseModel
    dup.isBaseModel = isBaseModel
    dup.debug = this.isAdmin
    streamReply(url, dup, token, opts)
  }
  
  sendChat = async (msg, isNewTopic, factCheck) => {
    const func = this.getFunc('sendChat')
    const dup = clone(msg)
    dup.thread = dup.topic
    delete dup.topic
    dup.utcOffset = this.utcOffset
    dup.isNewTopic = isNewTopic
    dup.factCheck = factCheck
    dup.isBaseModel = this.isBaseModel()
    const response = await func(dup)
    return response.data || {}
  }

  cancelStreamEdit = async id => {
    const db = this.firebase.firestore()
    const ref = db.collection('Edits').doc(id)
    const updates = {
      uid: this.self.uid,
      stopped: true,
      done: true
    }
    console.log("CANCEL STREAM EDIT", id, updates)
    await ref.set(updates, { merge: true })
  }

  streamEdit = async (instruction, input, buttonId, temperature, max_tokens, opts) => {
    const url = `${server}/streamEdit`
    const token = await this.getToken()
    return streamReply(url, {instruction, input, buttonId, temperature, max_tokens}, token, opts)
  }

  observeEdit = (instruction, input) => {
    if (!instruction) {
      throw new Error("instruction is required")
    }
    const db = this.firebase.firestore()
    const c = db.collection('Edits')
    const ref = c.doc()
    const updates = {
      ts: Date.now(),
      uid: this.self.uid,
      instruction,
      input: input || ''
    }
    return {
      cancel: () => this.cancelStreamEdit(ref.id),
      subscribe: observer => {
        let sub
        let output = ''
        const c1 = ref.collection('output').orderBy('ts', 'asc')
        const refs = []
        const deleteOutput = async () => {
          const batch = db.batch()
          refs.forEach(ref => batch.delete(ref))
          batch.delete(ref)
          await batch.commit()
        }
        ref.set(updates).then(async () => {
          console.log("STREAM EDIT", ref.id)
          const q = c1.where('id', '==', ref.id)
          sub = collectionChanges(q).pipe(catchError(err => {
            debugger
            throw "fun"
          })).subscribe(changes => {
            changes.map(change => {
              if (change.type == 'added') {
                refs.push(change.doc.ref)
                const data = change.doc.data()
                console.log("newText", data.newText)
                output += data.newText
                observer({
                  id: ref.id,
                  instruction,
                  input,
                  output,
                  done: data.isFinal
                })
              }
            })
          })
          await this.streamEdit(ref.id)
        })
        return {
          unsubscribe: () => {
            sub.unsubscribe()
            deleteOutput()
          }
        }
      }
    }
  }

  isAskAppOnly = () => {
    const value = window.location.origin.indexOf("attune") > 0 ||
          window.location.search.indexOf('attune') > 0
    console.log("isAskAppOnly", value)
    return value
  }

  receiveSharedThread = async (from, threadId) => {
    const func = this.getFunc('shareThread')
    await func({
      thread: threadId,
      from: from
    })
  }
                                                   

  shareThread = async (thread, messages) => {
    let  origin = window.location.origin
    const url =  origin + `/shareThread?topic=${encodeURIComponent(thread.topic)}&from=${this.self.uid}&id=${thread.id}`
    if (!isDesktop() && navigator.share) {
      const shareData = {
        title: thread.topic,
        text: thread.topic,
        url,
      }
      console.log("shareData", shareData)
      try {
        result = await navigator.share(shareData)
        console.log(result)
      } catch (err) {
        console.error(err)
      }
    } else {
      navigator.clipboard.writeText(url)
      await delay(0.5)
    }
    return url
  }

  observeHallucinationQuestions = () => {
    const db = this.firebase.firestore()
    const q =  db.collection('HallucinationQuestions')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const { type, doc } = change
        const data = doc.data()
        data.id = doc.id
        return {
          question: data,
          type
        }
      })
    }))
  }
}



