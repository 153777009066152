import { marked, TextRenderer } from "marked"
import { escape, unescape } from "lodash"

const block = (text) => text + "\n\n";
const escapeBlock = (text) => escape(text) + "\n\n";
const line = (text) => text + "\n";
const inline = (text) => text;
const newline = () => "\n";
const empty = () => "";

class Renderer extends TextRenderer {
  constructor() {
    super()
  }
  html() {
    return empty()
  }
  code(text) {
    return escapeBlock(text)
  }
  
  blockquote(text){
    return block(text)
  }
  heading(text) {
    return block(text)
  }
  hr() {
    return newline()
  }

  list(text) {
    return block(text.trim())
  }

  listitem(text) {
    return line(text)
  }

  checkbox() {
    return empty()
  }
  paragraph(text) {
    return block(text)
  }
  table(header, body) {
    return line(header + body)
  }
  tablerow(text){
    return line(text.trim())
  }
  tablecell(text){
    return text + " "
  }
  text(text) {
    return text;
  }

  link(href, title, text) {
    return '' + text;
  }

  image(_0, _1, text) {
    return text
  }
}


/**
 * Converts markdown to plaintext using the marked Markdown library.
 * Accepts [MarkedOptions](https://marked.js.org/using_advanced#options) as
 * the second argument.
 *
 * NOTE: The output of markdownToTxt is NOT sanitized. The output may contain
 * valid HTML, JavaScript, etc. Be sure to sanitize if the output is intended
 * for web use.
 *
 * @param markdown the markdown text to txtify
 * @param options  the marked options
 * @returns the unmarked text
 */
export async function markdownToTxt(
	markdown,
	options
) {
  const linkReg = /\[([^\]]+)\]\(([^)]+)\)/g
  markdown = markdown.replace(linkReg, (match, linkText, url) => {
    return `[${decodeURIComponent(linkText)}](${encodeURI(url)})`
  })
  let unmarked = await marked.parse(markdown, { renderer: new Renderer(), async: true, mangle: false })
  const unescaped = unescape(unmarked);
  const trimmed = unescaped.trim();
  return trimmed;
}
