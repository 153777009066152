import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { bindCallback, of, concat, from, Subject, merge as mergeN } from 'rxjs'
import { ReactSVG } from 'react-svg'
import { Keyboard, parseCode, renderCode, KeyboardAutocomplete, Document, InputControl, KeyboardButton, KeyboardButton1, KeyboardTitle } from '../Keyboard'
import { BnPage, BnSubpage } from '../Page'
import { UIButton } from '../chat/components/Button'
import { UIOKCancel } from '../chat/components/OKCancel'
import { isMobile, isDesktop } from '../../classes/Platform.js'
import { delay } from '../../classes/Util.js'
import AICheck from '../../assets/Icons/AICheck.svg'
import Spin from '../../assets/Icons/Spin.svg'
import Cross from '../../assets/Icons/Cross_1.svg'
import Trash from '../../assets/Icons/Trash.svg'
import Stop from '../../assets/Icons/Stop.svg'
import MenuUp from '../../assets/Icons/MenuUp.svg'
import MenuDown from '../../assets/Icons/MenuDown.svg'
import Category from '../../assets/Icons/UserSaid.svg'
import Undo from '../../assets/Icons/Redo.svg'
import Redo from '../../assets/Icons/Undo.svg'
import Mic from '../../assets/Icons/Mic_1.svg'
import Send from '../../assets/Icons/Send.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Share from '../../assets/Icons/Share.svg'
import UserSaid from '../../assets/Icons/UserSaid.svg'
import AISaid from '../../assets/Icons/AISaid.svg'
import OpenAI from '../../assets/Icons/OpenAI.svg'
import Meta from '../../assets/Icons/Meta.svg'
import Speaker from '../../assets/Icons/Speaker.svg'
import CheckMark from '../../assets/Icons/Tick.svg'
import EditIcon from '../../assets/Icons/UserSaid.svg'
import Plus from '../../assets/basketball/Plus.svg'
import Left from '../../assets/Icons/Back.svg'
import Right from '../../assets/Icons/Forward.svg'
import Alert from '../../assets/Icons/Alert.svg'
import Hashtag from '../../assets/Icons/Hashtag.svg'
import Marker from '../../assets/Icons/Marker.svg'
import Question from '../../assets/Icons/Question.svg'
import moment from 'moment'
import { langs } from '../../classes/Lang.js'
import {SpectrumAnalyzer} from '../SpectrumAnalyzer'
import ClickAwayListener from 'react-click-away-listener'
import Markdown from 'markdown-to-jsx'
import { getPortal } from '../Client'
import { useSwipeable } from 'react-swipeable'
import { parseTable, renderTable, renderTableMarkdown } from '../Keyboard/Table.js'
import './index.css'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {materialLight as CodeStyle} from 'react-syntax-highlighter/dist/esm/styles/prism'
import { markdownToTxt } from './MarkdownToTxt.js'

class RadioButtons extends Component {
  render() {
    const buttons = this.props.buttons.map(button => {
      const { selected } = button
      if (selected) {
        return <div className='keyboardRadioButtonSelected' onClick={button.select}>
                 <div className='keyboardRadioButtonIcon'><ReactSVG src={button.icon}/></div>
                 <div className='keyboardRadioButtonOn'>{button.label}</div>
               </div>
        
      } else {
        return <div className='keyboardRadioButtonUnselected' onClick={button.select}>
                 <div className='keyboardRadioButtonIcon'><ReactSVG src={button.icon}/></div>
                 <div className='keyboardRadioButtonOff'>{button.label}</div>
               </div>
      }
    });
    return <div className='keyboardRadioButton'>
             <div className='keyboardRadioButtonLabel keyboardRadioButton'>
               {this.props.label}
             </div>
             {buttons}
             <div className='keyboardRadioButtonRight'/>
           </div>

  }
}


const encodeURIExt = uri => {
  if (!uri.endsWith("%3f") && !uri.endsWith("%3F")) {
    uri = encodeURI(uri)
  }
  return uri
}

const QUESTIONS = ['Does Australia really have pink lakes?',
                   'When and on what topic  was the NYT\'s first report on "artificial intelligence"?',
                   'Can spiders fly?']
const links = [`Does Australia really have [pink lakes?](ai://?q=${encodeURIComponent(QUESTIONS[0])})`,
               `When and on what topic was the NYT's [first report on "artificial intellgence"](ai://?q=${encodeURIComponent(QUESTIONS[1])})?`,
               `Can [spiders fly?](ai://?q=${encodeURIComponent(QUESTIONS[2])})`]

const generateBlurb = (pre) => {
  pre = pre || `Hello, ask me any questions you'd like. `
  return `${pre}Here are some examples to get you started:\n
${links.map((q, i) => `\n- ${q}`)}\n`.replace(/,\n/g, '\n')
}

const CodeBlock = ({className, children}) => {
  let lang 
  if (className && className.startsWith('lang-')) {
    lang = className.replace('lang-', '');
    return (
      <SyntaxHighlighter language={lang} style={CodeStyle} showLineNumbers={true}>
        {children}
      </SyntaxHighlighter>
    )
  } else {
    if (children.indexOf('%') >= 0) {
      children = decodeURIComponent(children)
    }
    return <div className='aiCode'>{children}</div>
  }
}

// markdown-to-jsx uses <pre><code/></pre> for code blocks.
const PreBlock = ({children, ...rest}) => {
  if ('type' in children && children ['type'] === 'code') {
    return CodeBlock(children['props']);
  }
  return <div className='aiPre' {...rest}>{children}</div>;
};


const debugLog = (...args) => {
  //console.log.apply(null, args)
}


class CheckMarkComp extends Component {
  render( ){
    return <div className='aiCheck'><ReactSVG src={AICheck}/></div>
  }
}


const decodeURIComponentExt = c => {
  try{
    let decoded = decodeURIComponent(c)
    return decoded.replace(/[+]/g, ' ')
  } catch (exc) {
    debugger
    return c
  }
}

const Swiper = props => {
  const { onSwipeLeft, onSwipeRight } = props
  const config = {
    trackMouse: true,
    preventScrollOnSwipe: true,
  }
  const handlers = useSwipeable({
    onSwipedLeft: onSwipeLeft,
    onSwipedRight: onSwipeRight,
    ...config
  })
  return <div className={'swiper'} {...handlers}>
{props.children}
</div>
}

class Messages extends Component {
  constructor (props) {
    super(props)
  }
  componentWillUnmount() {
    document.removeEventListener("selectionchange", this.onSelectionChange)
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }
  componentDidMount() {
    const ref = this.scrollWindow
    this.props.onCreate(this)
    this.resizeObserver = new ResizeObserver(entries => {
      if (this.inScroll) return
      if (ref.scrollHeight == 0) return
      this.inResize = true
      if (ref.scrollTop !== this.lastScrollTop) {
        debugLog("onresize: scrollTop", this.scrollWindow.scrollTop, "last", this.lastScrollTop)
      }
      if (ref.scrollHeight !== this.lastHeight) {
        debugLog("onresize: scrollHeight", this.scrollWindow.scrollHeight, "last", this.lastHeight)
        this.lastHeight = ref.scrollHeight
      }
      if (ref.clientHeight !== this.lastOffsetHeight) {
        debugLog("onresize:scroll clientHeight", ref.clientHeight, "last", this.lastOffsetHeight)
        this.lastOffsetHeight = ref.clientHeight
      }
      const newScrollTop = this.computeScrollTop()
      debugLog("new scroll top: ", newScrollTop)
      const newScrollBottom = ref.scrollHeight - ref.clientHeight - newScrollTop
      debugLog("current scroll bottom: ", this.scrollBottom, "=>", newScrollBottom)
      ref.scrollTop = newScrollTop
      debugLog("resize scrollTop==>", newScrollTop)
      this.inResize = false
    })
    this.scrollBottom = 0
    this.lastHeight = ref.scrollHeight
    this.lastOffsetHeight = ref.clientHeight
    ref.scrollTop = this.computeScrollTop()
    this.lastScrollTop = ref.scrollTop
    debugLog("chat mounted scroll last: ", this.lastHeight)
    const onscroll = e => {
      let sizeDelta = 0
      if (this.inResize) {
        return
      }
      if (this.lastOffsetHeight !== ref.clientHeight) {
        debugLog("scroll client height change: ", ref.clientHeight, "last", this.lastOffsetHeight)
        sizeDelta += ref.clientHeight - this.lastOffsetHeight
        this.lastOffsetHeight = ref.clientHeight
      }
      if (this.lastHeight !== ref.scrollHeight) {
        debugLog("scroll height change: ", ref.scrollHeight, "last", this.lastHeight)
        sizeDelta += ref.scrollHeight - this.lastHeight
        this.lastHeight = ref.scrollHeight
      }
      if (sizeDelta === 0) {
        this.scrollBottom = this.computeScrollBottom()
        this.lastScrollTop = ref.scrollTop
      } else {
        const newValue = this.computeScrollTop()
        debugLog("onscroll scrollTop==>", newValue)
        return ref.scrollTop = newValue
      }
      debugLog("scrolltop=>", ref.scrollTop)
      debugLog("scrollHeight=>", ref.scrollHeight)
      debugLog("scrollClient=>", ref.clientHeight)
      debugLog("scrollBottom=>", this.scrollBottom)
      this.checkScrollBack()
    }
    ref.onscroll = e => {
      this.inScroll = true
      onscroll(e)
      this.inScroll = false
    }
    this.resizeObserver.observe(this.scrollWindow)
    this.resizeObserver.observe(this.scrollContent)
    this.windowListener = window.addEventListener("resize", this.onResized)
    this.scrollToBottom()
  }

  pushScrollBottom() {
    this.savedScrollBottom = this.scrollBottom
    //debugLog("pushScrollBottom: ", this.savedScrollBottom)
  }

  fixupScrollTopLater = () => {
    clearTimeout(this.fixupTimeout)
    setTimeout(this.fixupScrollTop)
  }

  setScrollWindow = ref => {
    if (ref && ref != this.scrollWindow) {
      this.scrollWindow = ref
    }
  }

  checkScrollBack = async () => {
    const ref = this.scrollWindow
    if (ref.scrollTop === 0 || ref.scrollHeight <= ref.offsetHeight) {
      debugger
      if (this.props.checkScrollBack) {
        this.props.checkScrollBack()
      }
    }
  }
  
  setScrollContent = ref => {
    if (ref && ref != this.scrollContent) {
      this.scrollContent = ref
    }
  }

  computeScrollTop = () => {
    const ref = this.scrollWindow
    const result = ref.scrollHeight - this.scrollBottom - ref.clientHeight
    debugLog(`computeScrollTop ${ref.scrollHeight} - ${this.scrollBottom} - ${ref.clientHeight} = ${result}`)
    return result
  }

  computeScrollBottom = () => {
    const ref = this.scrollWindow
    const result = ref.scrollHeight - ref.clientHeight - ref.scrollTop
    debugLog(`computeScrollBottom ${ref.scrollHeight} - ${ref.clientHeight} - ${ref.scrollTop} = ${result}`)
    return result
  }
  
  fixupScrollTop = () => {
    this.scrollWindow.scrollTop = this.computeScrollTop()
  }

  scrollToBottom=()=> {
    this.scrollBottom = 0
    this.fixupScrollTop()
  }

  render() {
    const messages = this.props.messages
    let containerClass = 'uiChatMessagesContainer'
    let marginClass = 'uiChatMarginBottom'
    if (this.props.searchFieldVisible) {
       marginClass += ' uiChatMarginBottomSearchField'
    }
    if (this.props.selectedThread) {
      marginClass += ' uiChatMarginBottomThread'
    }
    let topic
    if (this.props.selectedThread) {
      topic = this.props.selectedThread.topic
    }
    const onSwipeRight = (e) => {
      if (this.props.selectedThread) {
        this.props.selectThread(null)
      }
    }
    return <div key={this.props.key}  className={marginClass} >
             <div key='chatMessages' className={'uiChatMessages'} ref={this.setScrollWindow}>
               <div key='chatMessagesContainer' className={containerClass} ref={this.setScrollContent}>
                 {messages}
               </div>
             </div>
           </div>
   }

}

class Threads extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidUpdate() {
    if (this.props.threads.length === 0) {
      if (this.state.menuActive) {
        this.setState({
          menuActive: false
        })
      }
    }
  }
    
  renderMenu = () => {
    return ReactDOM.createPortal(this.doRenderMenu(), getPortal())
  }

  doRenderMenu = () => {
    const closeMenu = () => {
      //this.state.menuActive = false
      //this.forceUpdate()
    }
    const selectThread = thread => {
      this.state.menuActive = false
      this.props.selectThread(thread)
    }
    let menuStyle = (this.props.selectedThread || !this.state.menuActive) ? { display: 'none' } : null
    const newThread = async () => {
      selectThread({
        id: 'new-thread',
        topic: 'New Topic'
      })
    }
    let className = 'keyboardMenuItem keyboardMenuItemCategory'
    return <ClickAwayListener onClickAway={closeMenu}>    
      <div className='chatGptThreadsMenu' ref={this.setScrollRef}>
        {
          <div className='chatGptThreadMenuThreads' style={menuStyle} >
             {false && <div key={'new.thread'} className={className} onMouseDown={newThread}>
		<div className='keyboardMenuItemIcon'><ReactSVG src={Right}/></div>
		<div className='keyboardMenuItemLabel'>New Topic</div>
                       </div>}
               {
                this.props.threads.map(thread => {
                  const onClick = () => {
                    selectThread(thread)
                  }
                  return <div key={thread.id} className={className} onMouseDown={onClick}>
		           <div className='keyboardMenuItemIcon'><ReactSVG src={Hashtag}/></div>
		           <div className='keyboardMenuItemLabel'>{thread.topic}</div>
                         </div>
                  
                })
              }
            </div>
        }
      </div>
    </ClickAwayListener>
  }

  setScrollRef = ref => {
    if (ref) {
      ref.onscroll = () => {
        debugger
        if (ref.scrollHeight - ref.scrollTop === ref.offsetHeight) {
          if (this.props.checkScrollBack) {
            this.props.checkScrollBack()
          }
        }
      }
    }
  }

  
  render() {
    const onClick = async () => {
      clearTimeout(this.timeout1)
      this.timeout1 = setTimeout(() => {
        this.setState({
          menuActive: !this.state.menuActive
        })
      }, 100)
    }
    let menuStyle = (this.props.selectedThread || !this.state.menuActive) ? { display: 'none' } : null
    //console.log("threads", this.props.threads);
    const style = this.props.slide >= 0.5 ? { display: 'none' } : null
    return <div className='chatGptThreads' style={style}>
             <div className='keyboardAddButton'>
               <KeyboardButton1 keepFocus icon={this.state.menuActive ? MenuUp : MenuDown} action={onClick}/>
             </div>
             {!menuStyle && this.renderMenu()}
           </div>
  }
}

class Questions extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
    
  renderMenu = () => {
    return ReactDOM.createPortal(this.doRenderMenu(), getPortal())
  }

  doRenderMenu = () => {
    const closeMenu = (e) => {
      e.preventDefault()
      this.state.menuActive = false
      this.forceUpdate()
    }
    const selectQuestion = question => {
      this.state.menuActive = false
      this.props.selectQuestion(question)
    }
    return <ClickAwayListener onClickAway={closeMenu}>    
      <div className='chatGptQuestionsMenu'>
        {
            <div className='chatGptQuestionMenuQuestions'>
              {
                this.props.questions.map(question => {
                  const onClick = (e) => {
                    e.preventDefault()
                    selectQuestion(question)
                  }
                  let className = 'keyboardMenuItem keyboardMenuItemCategory'
                  return <div key={question.id} className={className} onMouseDown={onClick}>
		           <div className='keyboardMenuItemIcon'><ReactSVG src={Question}/></div>
		           <div className='keyboardMenuItemLabel'>{question}</div>
                         </div>
                  
                })
              }
            </div>
        }
      </div>
    </ClickAwayListener>
  }

  renderAutocompletes() {
    let questions = this.filterQuestions()
    debugLog("render autocompletes", questions)
    let className = 'keyboardMenuAutocompletes chatGPTQuestionsAutocomplete'
    const style = {
      position: 'absolute',
      bottom: 41,
      top: 'auto'
    }
    return <div className={className} style={style}>
    {
      questions.map(question => {
        const onClick = () => {
          this.props.ask(question)
        }
        const className= 'keyboardMenuItem keyboardMenuItemAutocomplete'
        return <div className={className} onMouseDown={onClick}>
                 <div className='keyboardMenuItemIcon'><ReactSVG src={UserSaid}/></div>
                 <div className='keyboardMenuItemLabel'>{question}</div>
               </div>
      })
    }
    </div>
  }

  filterQuestions = () => {
    let questions = this.props.questions
    let searchTerm = this.props.searchTerm
    debugLog("searchTerm", searchTerm)
    if (!searchTerm) {
      return []
    }
    searchTerm = searchTerm.toLowerCase()
    const searchTerms = searchTerm.split(/\s+/)
    debugLog('searchTerms', searchTerms.length)
    if (searchTerms.length > 2) {
      return []
    }
    const matches = {}
    let prefixMatch = searchTerms.length > 1
    let filtered = questions.filter(question => {
      if (!question) return false
      let matched = 0
      if (searchTerms.length > 0) {
        const label = question.toLowerCase()
        if (label.startsWith(searchTerm)) {
          matched += 10
          prefixMatch = true
        }
        const terms2 = label.split(/\s+/)
        ////debugLog('terms1', terms1)
        //debugLog('terms2', terms2)
        const allTerms = [terms2]//[terms1, terms2]
        allTerms.forEach((terms, i) => {
          //debugLog('terms', terms, i)
          terms.forEach((term, j) => {
            //debugLog('term', term)
            if (term) {
              searchTerms.forEach((searchTerm, k) => {
                if (searchTerm) {
                  if (term.startsWith(searchTerm)) {
                    matched++
                  }
                  if (term === searchTerm &&
                      j === k && k === 0) {
                    prefixMatch = true
                  }
                }
              })
            }
          })
        })
      } else {
        matched = 0
      }
      debugLog("matched", matched)
      if (matched === 0) return false
      const match = matches[question] || 0
      matches[question] = match + matched
      return true
    })
    if (prefixMatch) {
      filtered = filtered.filter(x => matches[x] >= 10)
    }
    debugLog('filtered', filtered)
    debugLog('matches', matches)
    filtered.sort((x, y) => {
      const w1 = matches[x] || 0;
      const w2 = matches[y] || 0;
      let cmp1 = w2-w1;
      if (cmp1 !== 0) {
        return cmp1;
      }
      return x.localeCompare(y)
    })
    return filtered
  }

  
  render() {
    const onClick = async () => {
      setTimeout(() =>  {
        this.setState({
          menuActive: !this.state.menuActive
        })
      }, 50)
    }
    let menuStyle = (!this.state.menuActive) ? { display: 'none' } : null
    return <div className='chatGptQuestions'>
             <div className='keyboardAddButton'>
               <KeyboardButton1 keepFocus icon={this.state.menuActive ? MenuDown : MenuUp} action={onClick}/>
             </div>
             {this.renderAutocompletes()}
             {!menuStyle && this.renderMenu()}
           </div>
  }
}


export class ChatGPT extends Component {

  constructor(props) {
    super(props)
    this.state = {
      slide: 0,
      edits: new Document(),
      completions: [],
      lang: {
        name: "English",
        dialect: "United States",
        iso: "en-US",
        hasVoice: true
      },
      threadBusy: false,
      swipeIndex: 0,
      selectedThreads: [],
      selectedModel: this.props.me.isAskAppOnly() ? this.props.isBaseModel ? 'davinci' : 'gpt-3.5-turbo' : 'default',
      hallucinationQuestions: []
    }
  }

  componentDidMount() {
    this.startTime = Date.now()
    setTimeout(this.init, 400)
    setTimeout(() => {
      this.setState({
        canShowBlurb: true
      })
    }, this.props.me.isAskAppOnly() ? 200: 1200)
    if (false && this.props.me.isAskAppOnly()) {
      this.chatSettingsSub = this.props.me.observeChatSettings().subscribe(settings => {
        let { model } = settings
        this.setState({
          selectedModel: model
        })
      })
    }
    if (this.props.me.isAskAppOnly() && !this.props.isBaseModel) {
      this.hallucinationsSub = this.props.me.observeHallucinationQuestions().subscribe(change => {
        const question = change.question
        if (change.type === 'removed') {
          delete this.hallucinationQuestions[question.id]
        } else {
          this.hallucinationQuestions[question.id] = question
        }
        this.setState({
          hallucinationQuestions: Object.values(this.hallucinationQuestions)
        })
      })
    }
  }
  
  hallucinationQuestions = {}

  init = () => {
    const model = this.state.selectedModel
    if (this.threadsSub) this.threadsSub.unsubscribe()
    if (this.messagesSub) this.messagesSub.unsubscribe()
    this.received = {}
    this.chatThreads = {}
    this.threadsSub = this.props.observeChatThreads(model).subscribe(change => {
      const { thread } = change
      if (change.type === 'removed') {
        delete this.chatThreads[thread.id]
      } else {
        this.chatThreads[thread.id] = thread
        if (this.state.selectedThread && this.state.selectedThread.id  === 'new-thread') {
          this.selectThread(thread)
        }
      }
      this.forceUpdate()
    })
    this.messagesSub = this.props.observeChatMessages(model).subscribe(change => {
      //console.log(change)
      const { message } = change
      let f
      if (change.type !== 'removed') {
        this.received[message.id] = message
        this.parseMessage(message)

        const wasPending = this.received['pending']
        if (wasPending && message.sent === wasPending.sent) {
          delete this.received['pending']
          delete this.cache['pending']
          if (this.state.selectedThread && message.topic === this.state.selectedThread.id) {
            this.state.searchResults = this.state.searchResults.filter(x => x.id != 'pending')
            this.state.searchResults.push(message)
          }
        }
        delete this.cache[message.id]
        if (this.state.selectedThread) {
          let found = false
          for (let i = 0; i < this.state.searchResults.length; i++) {
            const prev = this.state.searchResults[i]
            if (prev.id === message.id || prev.id === 'pending') {
              found = true
              this.state.searchResults[i] = message
              break
            }
          }
          if (!found) {
            if (this.state.selectedThread.id === 'new-thread'
                ||
                !message.topic
                ||
                message.topic === this.state.selectedThread.id) {
              this.state.searchResults.push(message)
            }
          }
        }
      } else {
        delete this.received[message.id]
        delete this.cache[message.id]
        if (this.state.searchResults) {
          this.state.searchResults = this.state.searchResults.filter(x =>
            x.id != message.id && x.inReplyTo != message.id)
        }
      }
      if (!message.inReplyTo) {
        this.restartDelayApologyTimer()
      }
      this.forceUpdate(f)
    })
  }

  chatThreads = {}
  received = {}

  restartDelayApologyTimer() {
    clearTimeout(this.delayApologyTimeout)
    this.delayApologyTimeout = setTimeout(() => {
      this.forceUpdate()
    }, 15000)
  }

  componentWillUnmount() {
    if (this.threadsSub) this.threadsSub.unsubscribe()
    if (this.messagesSub) this.messagesSub.unsubscribe()
    clearInterval(this.interval)
    if (this.chatSettingsSub) this.chatSettingsSub.unsubscribe()
    if (this.hallucinationsSub) this.hallucinationsSub.unsubscribe()
  }

  popScrollBottom() {
    debugLog("popScrollBottom: ", this.scrollBottom, " => ", this.savedScrollBottom)
    this.scrollBottom = this.savedScrollBottom || 0
    this.fixupScrollTop()
  }

  getMessages = () => {
    let messages
    messages = Object.values(this.received).filter(x => {
      if (x.from !== this.props.me.self.uid) {
        return this.received[x.inReplyTo]
      }
      return true
    })
    if (this.props.me.isAskAppOnly()) {
      const reply = {}
      for (const id in this.received) {
        const msg = this.received[id]
        if (msg.inReplyTo) {
          reply[msg.inReplyTo] = msg
        }
      }
      messages = messages.filter(x => {
        let model = x.model
        if (!model) {
          x = reply[x.id]
          if (!x) {
            debugger
          }
        }
        if (x && x.model) {
          if (x.model === this.state.selectedModel) {
            return true
          }
        } else {
        }
      })
    }
    const getTs = msg => {
      if (!msg.inReplyTo) {
        return msg.ts
      }
      const inReplyTo = this.received[msg.inReplyTo]
      return inReplyTo ? inReplyTo.ts : msg.ts
    }
    messages.sort((x, y) => {
      const t1 = getTs(x)
      const t2 = getTs(y)
      const cmp = t1 - t2
      if (cmp === 0) {
        return x.from === this.props.me.self.uid ? -1 : 1
      }
      return cmp
    })
    return messages
  }

  getThreadMessages = () => {
    if (!this.state.selectedThread) {
      return null
    }
    const messages = this.state.searchResults
    if (!messages) {
      return null
    }
    messages.sort((x, y) => {
      return x.ts - y.ts
    })
    return messages
  }

  getInReplyTo = message => {
    const { inReplyTo } = message
    return this.received[inReplyTo]
  }

  setMessages1 = ref => {
    this.messages1 = ref
  }

  setMessages2 = ref => {
    this.messages2 = ref
  }

  ask = async (topic, q, autoSend) => {
    if (this.received['pending']) {
      return
    }
    if (this.animating) {
      return
    }
    if (topic) {
      let currentTopic = this.getCurrentTopic()
      if (topic != currentTopic) {
        autoSend = false
        const thread = this.chatThreads[topic]
        this.selectThread(thread)
      }
    } else {
      autoSend = false
    }
    const result = this.editor.setText(q)
    if (isDesktop()) {
      this.setState({
        tooltip: ''
      })
    }
    if (autoSend) {
      this.sendChat()
    } 
  }

  renderAISaid = (message, onClick, opts) => {
    opts = opts || {}
    let { isReply, isBlurb, noSpin, isFactChecked, stop, isTrace, isHallucinated } = opts
    if (message.id === 'blurb') {
      isBlurb = true
      noSpin = true
    }
    const retry = async () => {
      const orig = this.received[message.inReplyTo]
      const text = orig.text
      delete this.received[orig.id]
      delete this.received[message.id]
      this.forceUpdate()
      this.props.deleteChatMessage(orig.id)
      await this.ask(orig.topic, text, true)
    }
    const copy = async () => {
      const text = await markdownToTxt(message.text)
      debugLog(text)
      navigator.clipboard.writeText(text)
      await delay(0.5)
    }

    const speak = async () => {
      if (message.speaking || this.state.speaking === message.id) {
        message.speaking = false
        this.state.speaking = false
        this.props.me.cancelSpeak()

        
      } else {
        message.speaking = true
        this.props.me.resetAudioSource()
        markdownToTxt(message.text).then(text => {
          this.props.me.speak(text, this.state.lang.iso).then(() => {
            message.speaking = false
            this.forceUpdate()
          })
        })
      }
      this.forceUpdate()
    }
    
    let { text, topic, code, table, isStreaming } = message
    isFactChecked = text && !isStreaming && !isBlurb && !message.trace
    isStreaming = isStreaming && (Date.now () - message.ts) < 120000
    const openaiBlurbs = [/[ \n]Is there anything else you would like to know[?]$/,
                          /[ \n]Is there anything else I can help you with[?]$/,
                          /[ \n]I hope this helps[!]$/,
                          /[ \n]Let me know if you have any further questions[.]$/,
                          /[ \n]If you have any further questions or need clarification, feel free to ask!$/,
                          /[ \n]If you have any further questions or need assistance, feel free to ask!$/,
                          /[ \n]Let me know if you'd like more information or have any further questions.$/]
    for (var i = 0; i < 2; i++) {
      for (const blurb of openaiBlurbs) {
        text = text.replace(blurb, '')
      }
    }
    //console.log('isFactChecked', isFactChecked, message)
    const factCheck = async () => {
    }
    let showSpeaker = true
    let showRetry = false
    let icon1 = AISaid
    if (this.props.me.isAskAppOnly()) {
      let model = message.model
      if (!model) {
        if (Date.now () - message.ts > 60000) {
          model = OpenAI
        } else {
          model = this.state.selectedModel
        }
      }
      if (isHallucinated || this.props.isBaseModel) {
        icon1 = model.startsWith('llama') ? Meta: OpenAI
      }
    }
    let icon2 = Copy
    let action = copy
    let className = 'keyboardEditDocument'
    let serverError = topic === 'system-error' 
    if (serverError) {
      icon1 = Alert
      className = 'keyboardEditDocument keyboardEditDocumentError'
      icon2 = null
      action = null
      onClick = null
      showSpeaker = false
      showRetry = true
    } else {
      if (isBlurb) {
        showSpeaker = false
        if (!serverError) {
          if (!noSpin) {
            icon2 = Spin
          } else {
          icon2 = null
          }
          action = async () => {}
          className += ' aiComment'
        } else {
          className += ' aiCommentError'
        }
      }
      else if (isReply) {
        className += ' aiReply'
      } else if (false && isStreaming) {
        icon2 = Stop
        className == ' aiStop'
        action = async () => {
        }
      }
    }
    if (isStreaming) {
      icon2 = Spin
      showSpeaker = false
    }
    if (isTrace) {
      showSpeaker = false
      className += ' aiTrace'
    }
    if (isHallucinated) {
      showSpeaker = false
      className += ' aiHallucinated'
    }
    const renderBody = () => {
      const components = {
        pre: PreBlock,
        code: CodeBlock,
        check: CheckMarkComp,
        a: ({href, title, children}) => {
          if (!href || !href.startsWith) {
            return null
          }
          const prefix = "ai://?q="
          let onClick
          let tooltip
          if (href.startsWith(prefix)) {
            const q = decodeURIComponentExt((href + ' ' + (title || '')).substring(prefix.length))
            onClick = (e) => {
              debugLog('q', q)
              debugger
              this.ask(message.topic, q)
            }
            tooltip = q
          } else {
            tooltip = href
            onClick = e => {
              this.props.me.openWindow(href)
            }
          }
          const nop = () => {
          }
          let enter
          let leave
          if (isDesktop()) {
            enter = (e) => {
              this.setState({
                tooltip
              })
            }
            leave = e => {
              this.setState({
                tooltip: ''
              })
            }
          }
          return <div onPointerEnter={enter} onPointerLeave={leave}
                      className='aiLink' onMouseDown={onClick}>{children}</div>
        }
      }
      let markdown = text || ''
      const linkReg = /\[([^\]]+)\]\(([^)]+)\)/g
      markdown = markdown.replace(linkReg, (match, linkText, url) => {
        return `[${decodeURIComponentExt(linkText)}](${encodeURIExt(url)})`
      })
      if (isFactChecked) {
        markdown  += "<check></check>"
      }
      return <Markdown children={markdown} options={{
                         overrides: components
                       }}/>
      
    }
    let speakerClassName = (message.id === this.state.speaking || message.speaking ? ' iconSpeakerActive' : '')
    let leftIconClassName = 'chatGPTLeftIcon copyAISaid'
    if (showRetry) {
      leftIconClassName += ' retryAISaid'
    }
    return <div key={message.id} className={className} onClick={onClick}>
             <div className='keyboardEditInstructionLeftIcon'>
               <ReactSVG src={icon1}/>
             </div>
             <div className={'keyboardEditDocumentText'}>
               <div className='keyboardEditDocumentTextInline'>
                 {message.code ? renderCode(message.code, this.copyToClipboard)  : renderBody()}
               </div>
             </div>
             <div className={leftIconClassName}>
               {!showRetry && <KeyboardButton1 keepFocus icon={icon2} action={action}/>}
               {showSpeaker && <KeyboardButton1 className={speakerClassName} keepFocus icon={Speaker} action={speak}/>}
               {showRetry && <KeyboardButton keepFocus label="Retry" icon={Send} action={retry}/>}
               {false && !isFactChecked && <div className='aiCheckButton'>
                                    <KeyboardButton1 keepFocus icon={AICheck} action={factCheck}/>
                                  </div>}
             </div>
           </div>
  }


  cache = {}
  
  renderChatMessage = (message, prev, next, mergedBody) => {
    const cached = this.cache[message.id]
    if (cached) return cached
    const thread = this.chatThreads[message.topic]
    const topic = thread ? thread.topic : ''
    const msg = message
    const time = Number(msg.ts);
    let sameDay = "h:mm a";
    let sameElse =  "MM/DD/YYYY \\a\\t ";
    const sameMinute = (t1, t2) => {
      const d1 = new Date(t1);
      const d2 = new Date(t2);
      return (d1.getYear() === d1.getYear() && d1.getMonth() == d2.getMonth() && d1.getDate() === d2.getDate() && d1.getHours() === d2.getHours() && d1.getMinutes() === d2.getMinutes());
    };
    if ((prev && sameMinute(time, prev)) || (next && sameMinute(time, next))) {
      sameDay = "h:mm:ss a";
    }
    const timestamp =  moment(time).calendar(null, {
      sameDay: sameDay,
      //lastDay: "[Yesterday] "+sameDay,
      //lastWeek: "[Last] dddd "+sameDay,
      sameElse: sameElse+ sameDay,
    })
    let className = 'chatGptChatMessageHeader'
    if (message.id !== 'blurb') {
      if (message.from === this.props.me.self.uid) {
        className += ' chatMessageFromUser'
      } else {
        className += ' chatMessageFromGpt'
      }
    }
    const noTime = message.from != this.props.me.self.uid || (next && (prev && msg.ts - prev.ts < 15 * 1000 * 60));
    let noTopic = message.topic === (prev ? prev.topic : null)
    if (!noTopic && next && next.text ===  topic) {
      noTopic = true
    }
    let className0 = 'chatGptChatMessage'
    if (message.topic === 'system-error') {
      className0 += ' chatGptChatMessageError'
    }
    const onSwipeLeft = e => {
      if (!message.code) {
        if (thread && !this.state.selectedThread) {
          this.selectThread(thread)
        }
      }
    }
    const onSwipeRight = e => {
      if (!message.code) {
        if (this.state.selectedThread) {
          this.selectThread(null)
        }
      }
    }
    const result = <div key={message.id} className={className0} >
                     <Swiper onSwipeLeft={onSwipeLeft} onSwipeRight={onSwipeRight}>
                       {!(noTime && noTopic) && <div className={className}>
                                                  <div className='chatGptChatMessageHeaderTopic'>{noTopic ? '' : '# ' + topic}</div>
                                                  {!noTime &&<div className='chatGptChatMessageTimestamp'>{timestamp}</div>}
                                                </div>}
                       <div className='chatGptChatMessageBody'>
                         {this.renderChatMessageBody(message, prev, next, mergedBody)}
                       </div>
                     </Swiper>
                   </div>
    if (this.state.selectedThread && this.wasAnswered(message)) {
      this.cache[message.id] = result
    }
    return result
  }

  isRecent = message => {
    const now = Date.now()
    return now - message.ts < 60000;
  }

  wasAnswered = (message) => {
    const reply = Object.values(this.received).find(x => x.inReplyTo === message.id)
    if (reply && reply.text) {
      if (message.stream) {
        message.stream = null
        delete this.cache[message.id]
      }
    }
    return reply
  }
  
  renderChatMessageBody = (message, prev, next, mergedBody) => {
    if (message.from === this.props.me.self.uid) {
      const del = async () => {
        await this.props.me.deleteChatMessage(message.id)
        delete this.received[message.id]
        if (this.state.searchResults) {
          this.state.searchResults = this.state.searchResults.filter(x => {
            return x.id !== message.id && x.inReplyTo !== message.id
          })
          if (this.state.searchResults.length === 0) {
            this.selectThread(null)
          } else {
            this.forceUpdate()
          }
        } else {
          this.forceUpdate()
        }
      }
      const now = Date.now()
      if (message.reaction && !message.reaction.split) {
        debugger
      }
      let user = <div key='usermsg' className='keyboardEditInstruction'>
               <div className='keyboardEditIconAndInstruction'>
                 <div className='keyboardEditInstructionLeftIcon'>
                 <ReactSVG src={UserSaid}/>
                 </div>
                 <div className='keyboardEditInstructionText'>
                   {message.text}
                 </div>
                 {message.id !== 'pending' && <div key='del' className='keyboardEditInstructionLeftIcon userSaidDel'>
                            <KeyboardButton1 keepFocus icon={Trash} action={del}/>
                          </div>
                 }
               </div>
               {message.reaction && <div key='reactions' className='chatGPTMessageReactions'>
                                      {
                                        message.reaction.split().map(reaction => {
                                          return <div className='chatGPTMessageReaction'>{reaction}</div>
                                        })
                                      }
                                    </div>}
                  </div>
      if ((this.isRecent(message) && !this.wasAnswered(message)) || message.stream) {
        let other
        if (message.stream) {
          other = this.renderAISaid({
            id: message.id + '-reply',
            ts: Date.now(),
            text: message.stream,
            topic: message.topic,
            isStreaming: true,
          })
        } else {
          let text = 'Contacting server.'
          if (now - message.ts > 15000) {
            text = 'I apologize for the delay. I am waiting for the server to process your request.'
          }
          if (message.reaction) {
            text = 'Working.'
          }
          other = this.renderAISaid({
            id: 'blurb-spinner',
            ts: message.ts,
            text,
          }, null, { isBlurb: true })          
        }
        return [user, other]
      }
      return user
    }
    const output = []
    let { outputTs, trace, progress, text, id, isStreaming, hallucinated, model } = message
    outputTs = outputTs || Date.now()
    if (!text && !progress) {
      progress = 'Working.'
    }
    if (!this.props.me.shouldTraceChatGPT()) {
      trace = null
      if (!text) {
        let start = this.progressMessage[id]
        if (!start) {
          start = Date.now()
          this.progressMessage[id] = start
        }
        trace = progress
      } else if (isStreaming || this.progressMessage[id]) {
        const now = Date.now()
        const start = this.progressMessage[id]
        if (now - start < 1000) {
          trace = progress
        }
      } else {
        delete this.progressMessage[id]
      }
    }
    if (hallucinated && !this.props.isBaseModel) {
      output.push(this.renderAISaid({
        id: 'hallucinated',
        text: hallucinated, topic: message.topic,
        model
      }, null, {
        isBlurb: true, noSpin: text, isTrace: true, isHallucinated: true, 
      }))
    }
    if (trace) {
      output.push(this.renderAISaid({
        id: 'trace', text: trace, topic: message.topic, isStreaming: message.isStreaming, model
      }, null, {
        isBlurb: true, noSpin: text, isTrace: true
      }))
    }
    if (text) {
      output.push(this.renderAISaid(message, null))
    }
    return output
  }

  progressMessage = {}

    
  isTextMessage = (message, withReactions) => {
    return true
  }
  
  renderMessages = rawMessages => {
    debugLog("messages", rawMessages)
    let messages
    const mergedBody = {}
    if (false) {
      messages = []
      let i = 0
      while (i < rawMessages.length) {
        let msg = rawMessages[i]
        if (this.isTextMessage(msg, true)) {
          let j = i + 1
          let lines 
          let ts = msg.ts
          let last
          let body
          while (j < rawMessages.length) {
            const next = rawMessages[j]
            if (next.from !== msg.from || !this.isTextMessage(next, false)) {
              break
            }
            if (next.ts - msg.ts > 15 * 1000 * 60) {
              break
            }
            if (!lines) {
              lines = [msg]
            }
            lines.push(next)
            last = next
            j++
            if (last.reaction && last.reaction.length > 0) {
              break
            }
          }
          if (last) {
            mergedBody[last.ts] = lines
            i = j-1
            msg = last
          }
          messages.push(msg)
          i++
        }
      }
    } else {
      messages = rawMessages
    }
    return messages.map((msg, i) => {
      const prev = i > 0 ? messages[i-1] : null
      const next = i + 1 < messages.length ? messages[i+1] : null
      return this.renderChatMessage(msg, prev, next, mergedBody)
    })
  }

  setSliderContainer = ref => {
    this.sliderContainer = ref
  }

  render() {
    const subpage = this.state.subpage ? this.state.subpage() : null
    const popup = this.state.popup ? this.state.popup() : null
    const content = this.renderContent()
    return  <BnPage me={this.props.me} subpage={subpage} popup={popup} safeArea={true}>
              {content}
            </BnPage>
    
  }

  shareThread = async (thread, messages) => {
    await this.props.me.shareThread(thread, messages)
  }
  
  renderContent() {
    let rawMessages = this.getMessages()
    let threaded = this.getThreadMessages() || []
    let buttonIcon = Send
    let buttonAction = async () => {
      return await this.sendChat()
    }
    let copyAction = this.editor && this.editor.isEmpty() ? null: this.copy
    let shareAction = null
    let busy = false
    let questions = []
    let placeholder = 'Ask me anything'
    let currentTopic
    if (this.state.slide > 0.5) {
      currentTopic = this.state.selectedThread ? this.state.selectedThread.id : null
    }
    let newTopicButton
    if (rawMessages.length > 0) {
      let message = rawMessages[rawMessages.length-1]
      //busy = message.from && !this.wasAnswered(message, true)
      if (!currentTopic) {
        currentTopic = message.topic
      }
    }
    if (this.state.selectedThread && this.state.selectedThread.id) {
      shareAction = () => this.shareThread(this.state.selectedThread, threaded)
    }
    busy = this.state.threadBusy
    let buttonLabel = 'Send'
    if (currentTopic && currentTopic !== 'new-thread') {
      const newTopic = async () => {
        return this.selectThread({
          id: 'new-thread',
          topic: 'New Topic'
        })
      }
      if (currentTopic) {
        const thread = this.chatThreads[currentTopic]
        if (thread) {
          placeholder = '# ' +thread.topic
        }
      }
      newTopicButton = <KeyboardButton className='newTopicButton' label={'Ask'} keepFocus action={newTopic} icon={Hashtag}/>
    }
    let messages = rawMessages
    let showSearchField = messages.length > 0
    if (messages.length === 0 ||
        (this.props.me.isAskAppOnly() && !this.state.selectedThread && !this.state.searchTerm)) {
      if (this.state.canShowBlurb) {
        let followUpQuestions
        let blurb
        if (this.props.me.isAskAppOnly() && !this.props.isBaseModel) {
          const questions = this.state.hallucinationQuestions.slice(0, 3)
          const links = questions.map(x => {
            const q = x.question
            return  `\n- [${q}](ai://?q=${encodeURIComponent(q)})`
          })
          if (!this.props.me.isAskAppOnly()) {
            followUpQuestions = QUESTIONS
            blurb = generateBlurb()
          } else {
            blurb =
              `${messages.length == 0 ? 'Welcome! ' : ''}This is a demonstration of Attunewise steering technology applied to LLM's fine-tuned for chat.<br/><br/>

Below are some example questions which trigger hallucinations in these models. Tap the menu for more examples or, of course, you can enter your own.<br/><br/>

For each question asked, a non-hallucinatory answer will be provided along with the default answer from the model (potentially containing hallucinations).\n
                 ${links.join('')}`
          }
                 
        } else {
         if (this.props.isBaseModel) {
           followUpQuestions = QUESTIONS
           blurb = generateBlurb(`${messages.length == 0 ? 'Welcome! ' : ''}This is a demonstration of Attunewise steering technology applied to LLM's that lack fine-tuning for chat.<br/><br/>Yet we are able steer turn-based chat, reduce hallucinations, and align with human values.<br/><br/> `)
         } else {
           followUpQuestions = QUESTIONS
           blurb = generateBlurb()
         }
        }
        let ts
        if (!messages.length) {
          ts = Date.now()
        } else {
          ts = this.startTime
        }
        const getTs = msg => {
          if (!msg.inReplyTo) {
            return msg.ts
          }
          const inReplyTo = this.received[msg.inReplyTo]
          return inReplyTo ? inReplyTo.ts : msg.ts
        }
        messages.push({
          ts: ts,
          text: blurb,
          id: 'blurb',
          followUpQuestions
        })
        messages.sort((x, y) => {
          const t1 = getTs(x)
          const t2 = getTs(y)
          const cmp = t1 - t2
          if (cmp === 0) {
            return x.from === this.props.me.self.uid ? -1 : 1
          }
          return cmp
        })
      } else {
        busy = true
      }
    }
    if (!this.state.selectedThread) {
       messages = this.state.searchResults || rawMessages
    }
    let questionsTopic
    if (!busy) {
      let msgs = [].concat(this.state.slide > 0.5 ? threaded : messages).reverse()
      if (msgs.length > 0) {
        const dup = {}
        let i = 0
        let message = msgs[i]
        console.log("message", message)
        while (!message.followUpQuestions && (i + 1) < msgs.length) {
          i++
          message = msgs[i]
        }
        msgs = msgs.slice(i)
        const topic = message.topic
        for (const x of msgs) {
          if (x.topic !== topic) {
            break
          }
          if (x.from === this.props.me.self.uid) {
            dup[x.text] = true
          } else if (x.followUpQuestions) {
            questions.push(x.followUpQuestions)
          }
        }
        questionsTopic = topic
        questions = questions.reverse().flatMap(x => x)
        questions = questions.filter(x => {
          if (!x) return false
          if (!dup[x]) {
            dup[x] = true
            return true
          }
          return false
        })
        console.log('questions', topic, questions.length)
      }
    }
    const x = this.state.slide * -(Math.min(window.innerWidth, 600) - 10)
    const sliderStyle = {
      transform: `translate(${x}px, 0)`
    }
    let index = this.state.swipeIndex
    let sliderClassName = 'chatMessagesSlider'
    if (this.state.selectedThread) {
      sliderClassName += ' chatMessagesSliderEnabled'
    }

    let showKeyboard = this.state.showKeyboard
    let menu
    if (this.state.hallucinationQuestions && !this.props.isBaseModel) {
      questionsTopic = null
      questions = this.state.hallucinationQuestions.map(x => x.question)
      questions.sort()
    }
    if (questions.length > 0) {
      const ask = q => this.ask(questionsTopic, q, true)          
      menu = <Questions ask={ask} searchTerm={this.state.questionSearchTerm} questions={questions} selectQuestion={ask} editorHeight={this.state.editorHeight}/>
    }
    const sfcStyle = {
      height: this.state.selectedThread ? 40 : 80
    }
    let style = !isDesktop() &&this.state.orient === 'landscape' ? { display: 'none' } : null
    let title
    let models
    if (this.props.me.isAskAppOnly()) {
      title = 'Attunewise.ai'
      const selectModel = model => {
        if (model === this.state.selectedModel) return
        if (this.state.selectedThread) {
          this.selectThread(null)
        }
        this.setState({
          selectedModel: model
        }, this.init)
        this.props.me.saveChatSettings({
          model
        })
      }
      let openAIModelLabel = this.props.isBaseModel ? "DAVINCI" : "GPT-3.5"
      let openAIModel = "gpt-3.5-turbo"
      let llamaModel = "llama-2-70b-chat"
      if (this.props.isBaseModel) {
         openAIModel = "davinci"
         llamaModel = "llama-2-70b"
      } 
      if (this.state.selectedModel === openAIModel) {
          title = <div className='chatGPTModelHeader'>
                    <div className='keyboardRadioButtonIcon'><ReactSVG src={OpenAI}/></div>
                    {this.props.isBaseModel ? "TEXT-DAVINCI-002" : "GPT-3.5 Turbo 0613"}
                  </div>
      } else {
          title = <div className='chatGPTModelHeader'>
                    <div className='keyboardRadioButtonIcon'><ReactSVG src={Meta}/></div>
                    {this.props.isBaseModel ? "LLAMA-2 70B" : "LLAMA-2 70B Chat"}
                  </div>
      }
      models = [
        {
          label: openAIModelLabel,
          selected: this.state.selectedModel === openAIModel,
          select: () => { selectModel(openAIModel) },
          icon: OpenAI
        },
        {
          label: "LLAMA-2",
          selected: this.state.selectedModel === llamaModel,
          select: () => { selectModel(llamaModel) },
          icon: Meta
        }
      ]
    }
    return <div className='chatGPT'>
             <div className='keyboardHeader' style={style}>
               <KeyboardButton1 icon={Left} action={this.goBack}/>
               <KeyboardTitle title={title}/>
               <div className='keyboardHeaderButton keyboardHeaderButtonCancel' onMouseDown={this.cancel}>
                 <ReactSVG src={Cross}/>
               </div>
             </div>
             <div className='chatMessagesSliderContainer' ref={this.setSliderContainer}>
               <div className={sliderClassName} style={sliderStyle}>
                 <Messages
                   searchFieldVisible={showSearchField}
                   selectedThread={null}
                   selectThread={this.selectThread}
                   onCreate={this.setMessages1} key='main' messages={this.renderMessages(messages)} checkScrollBack={this.checkScrollBack}/>
                 <Messages onCreate={this.setMessages2} key='threaded'
                           busy={this.state.threadBusy && !this.state.searchTerm}
                           selectedThread={this.state.selectedThread}
                           selectThread={this.selectThread}
                           searchFieldVisible={showSearchField}
                           messages={this.renderMessages(threaded)}/>
               </div>
             </div>
             <div className={'chatGPTInput' + (menu ? ' chatGPTInputWithMenu' : '')} >
               <InputControl onCreate={this.setInputRef}
                             busy={busy}
                             onKeyDown={this.onKeyDown}
                             placeholder={placeholder} me={this.props.me}
                             onSetEditor={this.setEditor}
                             onClear={this.clearEditor}
                             speechInputNoFocus={true}
                             speechInputActive={this.state.textFieldSpeechInputActive}
                             speechInputAction={this.toggleTextFieldSpeechInput}
                             selectSpeechInputLang={this.selectTextInputLang}
                             selectedLang={this.state.lang}
                             autodetect={this.autodetectLang} label={buttonLabel}
                             icon={buttonIcon} action={buttonAction}
                             copy={copyAction} share={shareAction}
                             cancel={undefined} undo={!this.isKeyboardShowing() &&
                                                      this.canUndo() && this.undoEdit}
                             redo={!this.isKeyboardShowing() && this.canRedo() &&
                                   this.redoEdit}
                             onBlur={() => {
                               this.setTextInputFocus(false) }}
                             onFocus={() => {
                               this.setTextInputFocus(true) }}
                             onInput={this.onInput}
                             applyCompletion={this.applyCompletion}
                             completions={this.state.completions}
                             bottomRow={models && <RadioButtons label='Models' buttons={models}/>}
                             menu={menu}/>
                             
               {isDesktop() && !this.props.me.isAskAppOnly() && <div className='chatGptTooltip'>{decodeURIComponentExt(this.state.tooltip || '')}</div>}
             </div>
             {showKeyboard && <div className='chatGPTKeyboard'>
              <Keyboard me={this.props.me} sendKeyboardOutput={this.sendKeyboardOutput} cancelKeyboardOutput={() => this.setState({showKeyboard: false})} isWritingAssistant={true}/>
                              </div>}
             {showSearchField && <div className='chatGptSearchFieldContainer'>
                                   {this.renderSearchField()}
                                   
                                 </div>}
           </div>
  }

  setInputRef = ref => {
    if (ref != this.inputRef) {
      this.inputRef = ref
      if (ref) ref.observeEditorHeight().subscribe(height => {
        this.setState({
          editorHeight: height
        })
      })
    }
  }

  checkScrollBack = async () => {
    let earliest = Date.now()
    for (const k in this.received) {
      const { ts } = this.received[k]
      earliest = Math.min(ts, earliest)
    }
    const prev = await this.props.getHistory(this.state.selectedModel, earliest, 10)
    for (const msg of prev) {
      this.parseMessage(msg)
      this.received[msg.id] = msg
    }
    this.forceUpdate()
  }

  setSearchEditor = editor => {
    if (this.searchEditorSub) {
      this.searchEditorSub.unsubscribe()
      this.searchEditorSub = null
    }
    this.searchEditor = editor
    if (editor) {
      this.searchEditorSub = editor.observeIsEmpty().subscribe(isEmpty => {
        this.setState({
          searchCanApply: !isEmpty
        })
      })
    }
    this.forceUpdate()
  }

  setEditor = ref => {
    if (this.editorSub) {
      this.editorSub.unsubscribe()
    }
    if (ref) {
      this.state.editorCanApply = true
      this.editor = ref
      this.editorSub = this.editor.observeIsEmpty().subscribe(isEmpty => {
        this.setState({
          editorCanApply: !isEmpty 
        })
      })
    }
    this.forceUpdate()
  }
  
  clearEditor = () => {
    this.state.completions = []
    this.renderCurrentDocument()
  }

  isKeyboardShowing = () => !isDesktop() && this.state.textInputFocus

  undoEdit = async () => {
    //debugger
    if (this.canUndoCurrentDocument()) {
      const doc = this.getCurrentDocument()
      doc.undo()
    } else {
      let i = this.getCurrentInstruction()
      this.state.variableToComplete = null
      if (i) {
        if (i.output) {
          this.state.edits.undo()
          i.undoOutput = i.output
          i.output = null
        } else if (i.inputText) {
          i.inputTemplate = null
          i.variableToComplete = null
          i.instruction = new Document(i.inputText)
          i.inputText = null
        } else {
          this.state.instructions.undo()
          i = this.getCurrentInstruction()
        }
        this.buildNextInstruction(i)
        this.renderCurrentDocument()
        return
      }
      if (this.state.instructions.canUndo()) {
        this.state.instructions.undo()
        const i = this.getCurrentInstruction()
        this.buildNextInstruction(i)
      } else {
        this.buildNextInstruction()
      }
    }
    this.renderCurrentDocument()
  }
  
  redoEdit = async () => {
    //debugger
    const doc = this.getCurrentDocument()
    if (doc && doc.canRedo()) {
      doc.redo()
    } else {
      let i = this.getCurrentInstruction()
      if (i) {
        if (i.undoOutput) {
          i.output = i.undoOutput
          i.undoOutput = null
          this.state.edits.redo()
          this.buildNextInstruction()
        } else {
          this.state.instructions.redo()
          i = this.getCurrentInstruction()
          this.buildNextInstruction(i)
        }
      } else if (this.state.instructions.canRedo()) {
        this.state.instructions.redo()
        i = this.getCurrentInstruction()
        this.buildNextInstruction(i)
      }
    }
    this.renderCurrentDocument()
  }

  showKeyboard = () => {
    if (true) return
    if (false) {
      const back = () => {
        this.setState({
          subpage: null,
          popup: null
        })
      }
      this.setState({
        popup: () => <div className='chatGPTKeyboard'><Keyboard me={this.props.me} sendKeyboardOutput={this.sendKeyboardOutput} cancelKeyboardOutput={back} isWritingAssistant={true}/></div>
      })
    } else {
      this.setState({
        showKeyboard: true
      })
    }
  }
    
  setTextInputFocus = textInputFocus => {
    this.showKeyboard()
    let instructionFocus = this.state.instructionFocus
    if (textInputFocus) {
      instructionFocus = false
    } else {
      this.onBlur()
    }
    this.setState({
      textInputFocus,
      instructionFocus
    },() => {
      this.updateLang()
      if (this.editor.focused) {
        this.onInput()
      }
    })
    if (!instructionFocus && !textInputFocus) {
      //this.stopVoiceInput()
    }
  }
  renderCurrentDocument = () => {
    const text = this.renderText(this.getCurrentDocument())
    if (this.editor.setText(text)) {
      this.focusedText = undefined
    }
    this.forceUpdate(this.showLastInstruction)
    return text
  }

  copyToClipboard = async text => {
    navigator.clipboard.writeText(text)
    await delay(0.5)
  }

  copy = async () => {
    const text = this.editor.getText()
    return this.copyToClipboard(text)
  }

  renderText = (edit) => {
    return (edit && edit.getCurrent()) || ''
  }

  getCurrentDocument = () => {
    return this.state.edits.getCurrent()
  }

  cancel = () => {
    this.props.back()
  }

  goBack = () => {
    this.props.back()
  }

  canUndo = () => {
  }

  canRedo = () => {
  }


  updateLang = () => {
  }

  inputSeq = 0
  onInput = async e => {
    //debugLog("onInput", e)
    const seq = ++this.inputSeq
    if (this.state.completions.length > 0) {
      this.state.completions = []
      this.forceUpdate()
    }
    this.setState({
      questionSearchTerm: this.editor.getText()
    })
  }

  clearError = () => {
  }

  onBlur = e => {
    this.state.editing = false
    this.state.completions = []
    const text = this.editor.getText()
    if (this.getCurrentText() == text) {
      //debugLog("onBlur no change")
      this.forceUpdate()
      return 
    }
    //debugLog("onBlur text changed")
    this.clearError()
    const doc = this.getCurrentDocument()
    ////debugger
    this.forceUpdate()
  }

  getCurrentText = () => {
    const doc = this.getCurrentDocument()
    return doc ? doc.getCurrent() || "" : ""
  }

  getCurrentTopic = () => {
    let topic
    if (this.state.selectedThread) {
      topic = this.state.selectedThread.id 
    } else {
      let ts = 0
      let lastMessage
      for (const id in this.received) {
        const message = this.received[id]
        if (message.ts > ts) {
          ts = message.ts
          lastMessage = message
        }
      }
      if (lastMessage) {
        topic = lastMessage.topic
      }
    }
    return topic
  }

  sendChat = async () => {
    const text = this.editor.getText().trim()
    if (text) {
      if (this.state.selectedThread) {
        this.messages2.scrollToBottom()
      } else {
        this.messages1.scrollToBottom()
      }
      let topic = this.getCurrentTopic()
      let isNewTopic = false
      if (topic === 'new-thread') {
        topic = undefined
        isNewTopic = true
      }
      if (!this.state.selectedThread) {
        topic = undefined
      }
      const sent = Date.now()
      const msg = {
        id: 'pending',
        text,
        ts: Date.now(),
        from: this.props.me.self.uid,
        sent,
        model: this.state.selectedModel
      }
      this.received['pending'] = msg
      msg.topic = topic
      if (this.state.selectedThread) {
        this.state.seardchResultsBusy = true
        this.state.searchResults.push(msg)
      } 
      this.state.isStreaming = true
      this.forceUpdate()
      /*
      this.props.me.sendChat(msg, isNewTopic).then(result => {
        const {error} = result
        if (error) switch (error) {
          case 'not-enough-tokens':
          return this.notEnoughTokens()
        }
        })
      */
      let spoken = 0
      let completeText = ''
      const getMessage = () => {
        let found = msg
        for (const id in this.received) {
          const msg = this.received[id]
          if (msg.sent === sent) {
            const reply = this.wasAnswered(msg)
            if (reply) {
              found = reply
              if (msg.stream) {
                msg.stream = null
                delete this.cache[msg.id]
              }
            } else {
              found = msg
            }
            break
          }
        }
        return found
      }

      const getReplyMessage = () => {
        let found = getMessage()
        if (!this.state.selectedThread) {
          if (found.inReplyTo) {
            return found
          }
          return this.wasAnswered(found)
        } else {
          let id = found.inReplyTo || found.id
          return this.state.searchResults.find(x => x.inReplyTo === id)
        }
      }
      
      const flushToSpeaker = async (isFinal) => {
        if (this.recognition && isFinal) {
          const reply = getReplyMessage()
          console.log("GOT REPLY", reply)
          this.state.speaking = reply.id
          let lang = this.state.lang.iso
          this.props.me.resetAudioSource()
          this.recognition.stop()
          this.forceUpdate()
          return this.props.me.speak(completeText, lang).then(() => {
            this.state.speaking = null
            this.forceUpdate()
            this.recognition.start()
          })
        }
      }
      this.props.streamChat(msg, isNewTopic, {
        model: this.state.selectedModel,
        onContent: text => {
          const found = getMessage()
          if (found) {
            if (found.from == this.props.me.self.uid) {
              found.stream = text
            } else {
              found.text = text
            }
            completeText = text
            flushToSpeaker()
            delete this.cache[found.id]
            this.forceUpdate()
          } else {
            debugger
          }
        },
        onDone: () => {
          flushToSpeaker(true).then(() => {
            this.state.isStreaming = false
            this.forceUpdate()
          })
        },
        onError: (err) => {
          this.state.isStreaming = false
          this.forceUpdate()
        }
      })
      this.clearEditor()
    }
    if (isMobile()) {
      this.editor.blur()
    }
  }

  notEnoughTokens = instruction => {
  }

  getThreads = () => {
    if (this.state.slide >= 0.5) {
      return []
    }
    let threads = Object.values(this.chatThreads).filter(t => t.topic)
    if (this.state.searchResults && !this.state.selectedThread) {
      threads = threads.filter(x => this.state.searchResults.find(y => y.topic == x.id))
    }
    threads.sort((x, y) => {
      return y.lastUpdated - x.lastUpdated
    })
    return threads
  }

  setAutocomplete = ref => {
    this.autocomplete = ref
    if (this.inputRef && ref) {
      ref.setInput(this.inputRef)
    }
  }

   selectThread = thread => {
     clearTimeout(this.timeout)
     clearInterval(this.interval)
     const target = thread ? 1.0 : 0.0
     const left = this.sliderContainer.scrollLeft
     const fraction = left / window.innerWidth
     debugLog("LEFT", left)
     const dur = 600
     const start = Date.now() - fraction * dur
     let delay = 0
     this.animating = true
     this.timeout = setTimeout(() => {
       clearInterval(this.interval)
       this.interval = setInterval(() => {
         window.requestAnimationFrame(() => {
           const elapsed = (Date.now() - start)
           let t = Math.min(elapsed / dur, 1.0)
           t = 1 - Math.pow(1 - t, 7)
           const updates = {
             slide: target ? t : 1.0 - t
           }
           let f
           if (t >= 1) {
             this.animating = false
             clearInterval(this.interval)
             if (!thread) {
               this.state.selectedThreads.pop()
               updates.selectedThread = this.state.selectedThreads[this.state.selectedThreads.length]
               updates.threadBusy = false
             }
             f = this.performSearch
           }
           this.setState(updates, f)
         })
       }, 1000/60)
     }, delay)
     if (thread) {
       this.state.selectedThreads.push(thread)
       this.setState({
         threadBusy: true,
         selectedThread: thread
       }, this.performSearch)
    } else {
      this.editor.clear()
    }
  }

  seqNum = 0
  search = async searchTerm => {
    this.state.searchTerm = searchTerm.trim()
    this.forceUpdate()
    this.performSearch()
  }

  parseMessage = message => {
    if (!message.topic) {
      if (message.inReplyTo) {
        const src = this.received[message.replyTo]
        if (src) {
          message.topic = src.topic
        }
      }
    }
    const code = parseCode(message.text)
    if (code) {
      message.code = code
    } else {
      const table = parseTable(message.text)
      if (table) {
        message.table = table
      }
    }
  }

  performSearch = async () => {
    const seq = ++this.seqNum
    const searchTerm = this.state.searchTerm
    let topic = this.state.selectedThread ? this.state.selectedThread.id : ''
    if (searchTerm || topic) {
      this.state.searching = true
      if (!this.state.searchTerm) {
        this.state.threadBusy = true
      }
      this.forceUpdate()
      const { results, page, out_of }  = await this.props.searchChatMessages(searchTerm, topic, this.state.selectedModel)
      debugLog("SEARCH", seq, this.seqNum, results)
      results.forEach(message => {
        this.parseMessage(message)
      })
      if (seq === this.seqNum) {
        if (topic && !searchTerm) {
          const byId = {}
          results.forEach(x => {
            byId[x.id] = x
          })
          const messages = this.getMessages()
          for (const message of messages) {
            if (message.id !== 'pending' && message.topic === topic && !byId[message.id]) {
              results.push(message)
            }
          }
        }
        this.setState({
          searching: false,
          threadBusy: false,
          searchResults: results
        })
      }
    } else {
      this.setState({
        searching: false,
        searchResults: null,
        threadBusy: false
      })
    }
  }

  onKeyDown = e => {
    const RETURN = "Enter";
    if (isDesktop()) {
      if (e.key === RETURN && !e.shiftKey) {
        e.preventDefault()
        this.sendChat()
      }
    }
  }


  renderSearchField = () => {
    let busy = this.state.searching && this.state.searchTerm
    let searchTerm = ''
    const clear = () => {
      // fixme!!
      this.searchEditor.clear()
      this.search('')
    }
    const onFocus = () => {
    }
    const onBlur = () => {
    }
    const onInput = () => {
      this.search(this.searchEditor.getText())
    }
    const selectThread = thread => {
      this.selectThread(thread)
    }
    let icon
    let label
    let action
    if (this.state.selectedThread) {
      action = async () => {
        this.selectThread(null)
      }
    } else {
      action = async () => {
        return this.selectThread({
          id: 'new-thread',
          topic: 'New Topic'
        })
      }
    }
    if (this.state.slide < 0.5) {
      label = 'New'
      icon = Hashtag
    } else {
      label = 'Back'
      icon = Left
    }
    const newTopicButton = <KeyboardButton className={'newTopicButton'} icon={icon} action={action} label={label}/>
    const selectedThread = this.state.selectedThread
    return <div key='nextInstruction' className='keyboardInstructionInput'>
             <InputControl
               busy={busy}
               middleLeft={newTopicButton}
               me={this.props.me}
               placeholder={'Search'}
               onSetEditor={this.setSearchEditor}
               downward={true}
               onClear={clear}
               onFocus={onFocus}
               onBlur={onBlur}
               onInput={onInput}
               menu={<Threads checkScrollBack={this.getThreadsHistory} slide={this.state.slide} selectThread={selectThread} selectedThread={selectedThread} threads={this.getThreads()}/>}                   
             />
             </div>
  }


  getThreadsHistory = async () => {
    if (!this.state.selectedThread && !this.state.searchResults) {
      let earliest = Date.now()
      for (const k in this.chatThreads) {
        const t = this.chatThreads[k]
        earliest = Math.min(t.lastUpdated, earliest)
      }
      const prev = await this.props.getThreadsHistory(this.state.selectedModel, earliest, 30)
      for (const thread of prev) {
        this.chatThreads[thread.id] = thread
      }
      this.forceUpdate()
    }
  }

  toggleTextFieldSpeechInput = async () => {
    const textFieldSpeechInputActive = !this.state.textFieldSpeechInputActive
    if (this.state.voiceRecognitionActive) {
      this.toggleVoiceRecognition()
    }
    this.setState({
      textFieldSpeechInputActive,
      instructionSpeechInputActive: false
    }, () => {
      if (textFieldSpeechInputActive) {
        this.toggleVoiceRecognition()
      }
    })
  }
  
  toggleVoiceRecognition = async e => {
    if (e) e.preventDefault()
    ////debugger
    this.state.voiceRecognitionActive = !this.state.voiceRecognitionActive
    if (this.recognition) {
      this.recognition.stop()
      this.recognition = null
    }
    if (!this.state.voiceRecognitionActive) {
      this.setState({
        instruction: null
      })
      if (this.sub3) {
        this.sub3.unsubscribe()
        this.sub3 = null
      }
      if (this.sub4) {
        this.sub4.unsubscribe()
        this.sub4 = null
      }
    } else {
      this.recognition = this.props.me.getVoiceRecognizer()
      this.sub3 = this.recognition.observeIsActive().subscribe(isActive => {
        this.state.voiceRecognitionActive = isActive
        this.forceUpdate()
      })
      this.sub4 = this.recognition.observeInstruction().subscribe(instruction => {
        this.receiveVoiceInput(instruction)
      })
      this.updateLang()
      this.recognition.start()
    }
    this.forceUpdate(this.updateLang)
  }

  selectTextInputLang = lang => {
    localStorage.setItem('keyboard.text.lang', JSON.stringify(lang))
    this.setState({
      lang
    }, this.updateLang)
  }
  
  updateLang = () => {
    if (this.state.instructionSpeechInputActive) {
      if (this.recognition) {
        this.recognition.setLang(this.state.instructionLang.iso)
      }
    }
    else if (this.state.textFieldSpeechInputActive) {
      if (this.recognition) {
        this.recognition.setLang(this.state.lang.iso)
      }
    } else {

    }
  }

  speakText = async (cancel) => {
    if (cancel) {
      this.props.me.cancelSpeak()
    } else {
      const text = this.editor.getText()
      this.props.me.resetAudioSource()
      await this.props.me.speak(text, this.state.lang.iso)
    }
  }

  receiveVoiceInput = async input => {
    ////console.log("voice input", input)
    if (this.state.instructionSpeechInputActive) {
      const { instruction } = this.state.nextInstruction
      const editor = this.instructionEditor
      editor.insertTextAtCaret(input)
      const text = editor.getText()
      instruction.advance(text)
      const { isComplete, corrected } = await this.props.me.autocorrect({input: text, lang: this.state.instructionLang.iso})
      if (corrected && text != corrected) {
        instruction.undo()
        instruction.advance(corrected)
        editor.setText(corrected)
      }
    } else if (this.state.textFieldSpeechInputActive) {
      this.editor.insertTextAtCaret(input)
      const text = this.editor.getText()
      const data  = await this.props.me.autocorrect({input: text, lang: this.state.lang.iso})
      const { isComplete, corrected } = data
      if (corrected && text != corrected) {
        this.editor.setText(corrected)
      }
      if (isComplete && !this.state.isStreaming) {
        this.sendChat()
      }
    } else {
      console.error("voice input fail")
    }
    this.forceUpdate()
  }
}

