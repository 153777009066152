import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { BnLabel1, BnLabel2 } from '../Label'
import { BnPage, BnSubpage } from '../Page'
import { BnForm, BnFormFields, BnFormFieldSeparator as Sep } from '../Form'
import { BnButton, BnBackButton } from '../Button'
import { BnInputField, BnInputFieldSeparator } from '../TextInput'
import { isIOS, consoleLog, isDesktop, isSafari, isChrome } from '../../classes/Platform.js'
import { Home, HomeButton, HomeInput } from '../Home'
import { Keyboard } from '../Keyboard'
import IOSSettings from '../../assets/Icons/iOSSettings.svg'
import IOSGeneralSettings from '../../assets/Icons/iOSGeneralSettings.svg'
import IntelliKeyLogo2D from '../../assets/Icons/2DLogo.svg'
import Enable from '../../assets/Icons/Enable.svg'
import _2DLogoKeyboard from '../../assets/Icons/2DLogoKeyboard.png'
import SignIn from '../../assets/Icons/Signin.svg'
import Alert from '../../assets/Icons/Alert.svg'
import Phone from '../../assets/Icons/Phone.svg'
import phone from 'phone';
import './index.css'
const delay = seconds => new Promise(resolve => setTimeout(resolve, seconds*1000))

export const Oof = props => {
  let message = props.error ? props.error.message: ''
  let style
  style = message ? null : { display: 'none' }
  return <div className='keyboardOof' style={style}>
           <HomeButton icon={Alert}
                       sublabel={props.sublabel}
                       buttonLabel={message} action={props.action}/>
           </div>
}

const KeyboardLogo = props => {
  return <div className='keyboardLogo'>
           <div className='keyboardLogoImg'>
             <img src={props.icon}/>
           </div>
           {props.text || "IntelliKey"}
           </div>
}

const KeyboardStep = props => {
  return <div className='keyboardSettingsStep'>
           <div className='keyboardSettingsStepNumber'>
             {props.stepNumber}
           </div>
           <div className='keyboardSettingsStepIcon'>
             { props.icon && <ReactSVG src={props.icon}/>}
           </div>
           <div className='keyboardSettingsStepText'>
             {props.text}
           </div>
         </div>
}

const steps = [
  {
    icon: IOSSettings,
    text: <span className='settingStepText'>Go to&nbsp;<span className='settingsStepBold'>Settings</span></span>
  },
  {
    icon: IOSGeneralSettings,
    text: <span className='settingStepText'>Go to&nbsp;<span className='settingsStepBold'>General</span></span>
  },
  {
    text: <span className='settingStepText'>Go to&nbsp;<span className='settingsStepBold'>Keyboard</span></span>
  },
  {
    text: <span className='settingStepText'>Go to&nbsp;<span className='settingsStepBold'>Keyboards</span></span>
  },
  {
    type: 'installation',
    text: <span className='settingStepText'>Go to&nbsp;<span className='settingsStepBold'>Add New Keyboard...</span></span>
  },
  {
    icon: IntelliKeyLogo2D,
    type: 'installation',
    text: <span className='settingStepText'>Add&nbsp;<span className='settingsStepBold'>Intelligent - Keyboard</span></span>
  },
  {
    text: <span className='settingStepText'>Select&nbsp;<span className='settingsStepBold'>Intelligent - Keyboard</span></span>
  },
  {
    icon: Enable,
    text: <span className='settingStepText'>Enable&nbsp;<span className='settingsStepBold'>Allow Full Accesss</span></span>
  }
  ]

class KeyboardSettings extends BnSubpage {

  takeMeThere = () => {
    this.props.me.openKeyboardSettings()    
  }
  renderContent() {
    const done = this.props.done
    let title = this.props.done ? "Keyboard is Enabled" : "Enable Intelligent Keyboard"
    const filteredSteps = steps.filter(x => {
      return this.props.isInstalled ? x.type !== 'installation' : true
    })
    return <div className='keyboardSettingsContent'>
             {done && <div className='keyboardInstallationDoneSpacer1'/>}
             <div className='keyboardSettingsTitle'>
               <BnLabel1 text={title}/>
             </div>
             {!done ? <div className='keyboardSettingStepsContainer'>
                         <div className='keyboardSettingSteps'>
                           {
                            filteredSteps.map((step, i) => {
                               let text = step.text
                               if (i == 0) {
                                 text = <div className='keyboardSettingStep2'>
                                          {text} <div className='keyboardSettingStep2TakeMeThere' onClick={this.takeMeThere}>Take me there</div>
                                        </div>
                               }
                               return <KeyboardStep
                                        stepNumber={i + 1}
                                        icon={step.icon}
                                        text={text}/>
                               
                             })
                           }
                         </div>
                      </div>
              :
              <div className='keyboardInstallationDoneSpacer2'/>
             }
             <BnButton label={'Done'} action={this.props.doneClicked}/>
             </div>
  }
}


export class GetStarted extends Component {
  componentDidMount() {
    if (!this.props.me) {
      debugger
    }
  }
  render() {
    let text = this.props.text
    let icon = this.props.icon || _2DLogoKeyboard 
    if (this.props.me && this.props.me.isAskAppOnly()) {
      //text = "Attunewise.ai"
      //icon = IntelliKeyLogo2D
    }
    return <div className='keyboardGetStartedPage'>
             <div className={'keyboardGetStartedContent' + (this.props.className ? ' ' + this.props.className : '')}>
               <div className='keyboardGetStartedLogo' text={this.props.text}>
                  <KeyboardLogo text={text} icon={icon}/>
               </div>
               <div className='keyboardGetStartedMiddle'>
                 {this.props.children}
               </div>
               {this.props.action && <div className='keyboardGetStartedButton'>
                                       <BnButton label={this.props.label || 'Get Started'} action={this.props.action}/>
                                     </div>}
             </div>
           </div>
  }
}


class KeyboardCreateAccount extends BnSubpage {

  componentDidUpdate() {
    if (this.props.form.onVerificationCodeInput) {
      if (!this.state.subpage) {
        this.next()
      }
    } else {
    }
  }

  next = () => {
    const back = () => {
      const form = this.getForm()
      form.onVerificationCodeInput = null
      this.back()
    }
    this.setState({
      subpage: () => <KeyboardVerificationCode me={this.props.me} form={this.getForm()} formErr={this.getFormErr()} onChange={this.onChange} next={this.props.next} back={back}/>
    })
  }

  renderContent() {
    let text = 'Sign-in or Create Account'
    if (this.props.back) {
      //text = "Phone Number Verification"
    }
    const form = this.getForm()
    let formErr = this.getFormErr()
    return <GetStarted className='signInCreateAccount' me={this.props.me} text={text} icon={SignIn} label={'Next'} action={this.props.next}>
      <BnLabel2 text='Phone Number'/>
      <div className='bnSignInFields'>
      <BnInputField key='phone'  name='phoneNumber' label={''} formErr={formErr} form={form} type='tel' onChange={this.onChange} autoComplete={'tel'} busy={form.busy}/>
      </div>
        <div className='bnReferralCodeContainer'>
      <div className='bnReferralCode'>
        <BnLabel2 text='referral code'/>
        <div className='bnSignInFields'><BnInputField key='code' name='referralCode' label={''} formErr={this.getFormErr()} form={form} onChange={this.onChange} type={'number'}/> </div>
      </div>
        </div>
      <Oof error={formErr}/>
      </GetStarted>
  }  
}

class KeyboardVerificationCode extends BnSubpage {

  renderContent() {
    const form = this.getForm()
    const formErr = this.getFormErr()
    console.log('formErr', formErr)
    return <GetStarted back={this.props.back} className='signInVerificationCode' me={this.props.me} text="Enter your Verification Code" icon={Phone} label={'Next'} action={this.props.next}>
             <BnLabel2 text='Verification Code (check your messages)'/>
             <div className='bnSignInFields'><BnInputField key='code' name='verificationCode' label={''} formErr={this.getFormErr()} form={form} type='number' autocomplete={'one-time-code'} onChange={this.onChange}  busy={form.busy}/> </div>
             <Oof error={formErr}/>
           </GetStarted>
  }
}


export class KeyboardLogin extends BnSubpage {


  componentDidMount() {
    this.set('countryCode', 1)
    if (this.props.back) {
      this.createAccount()
    }
    this.set('referralCode', this.props.me.getInviteCode())
    if (this.props.onCreate) this.props.onCreate(this)
    this.sub = this.props.me.observeSelf().subscribe(self => {
      if (!this.state.subpage) {
        this.props.next()
      }
      this.setState({
        self
      })
    })
    if (this.props.autoCreateAccount) {
      debugger
      this.createAccount()
    }
  }
  componentWillUnmount() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  signIn = async () => {
    const form = this.getForm()
    let err
    if (!form.email) {
      err = {field: 'email', message: 'Email address is required'}
      this.setState({formErr: err})
      return
    }
    if (!form.password) {
      err = {field: 'password', message: 'Password is required'}
      this.setState({formErr: err})
      return
    }
    try {
      await this.props.me.signIn(form.email, form.password)
      this.setState({
        needsSignIn: false
      })
    } catch (err) {
      consoleLog(err)
      const message = err.message;
      let formErr
      if (err.code == "auth/user-not-found") {
        formErr = {field: 'email', message: "No user with that email."};
      } else if (err.code == "auth/too-many-requests") {
        formErr = {field: 'email', message: "Too many attempts. Try again later."};
      } else if (err.code == "auth/wrong-password") {
        formErr = {field: 'password', message: "Password incorrect."};
      } else if (err.code == "auth/network-request-failed") {
        formErr = {message: "You are not connected to the Internet."};
      } else if (err.code == "auth/invalid-verification-code") {
        formErr = {message: "Invalid verification code."};
      } else if (err.code) {
        formErr = {field: '', message: err.message};
      } else {
        return Promise.reject(err);
      }
      this.setState({
        formErr
      })
    }
  }

  signUp = async () => {
    const form = this.getForm()
    this.state.formErr = {}
    if (!form.phoneNumber) {
      this.setState({
        formErr: {
          field: 'phoneNumber',
          message: 'Phone number is required.'
        }
      })
      return
    }
    let phoneNumber = form.phoneNumber
    const converted = phone(phoneNumber);
    if (!converted.length) {
      this.state.formErr = {field: 'phoneNumber', message: 'Invalid phone number.'};
      this.forceUpdate()
      return
    } else {
      phoneNumber = converted[0];
    }
    const handleError = err => {
      form.busy = false
      let message = err.message
      if (err.code === 'auth/quota-exceeded') {
        message = 'Too many sign-in attempts from this number. Try again later.'
      } else if (err.code == "auth/too-many-requests") {
        message = 'Too many attempts. Try again later.'
      } else if (err.code == 'auth/internal-error') {
        message =  'Internal Error. Try again later.'
      } else if (err.code == "auth/network-request-failed") {
        message =  'You are not connected to the Internet.'
      } else if (err.code == "auth/invalid-verification-code") {
        formErr = {message: "Invalid verification code."};
      }  else {
        debugger
      }
      this.setState({
        formErr: { field: 'phoneNumber', message: message }
      })
    }
    if (!form.phoneNumberChecked) {
      form.phoneNumberChecked = true
      form.busy = true
      this.forceUpdate()
      try {
        const { exists } = await this.props.me.phoneNumberExists(phoneNumber)
        form.busy = false
        form.isSignUp = !exists
        this.forceUpdate()
      } catch (err) {
        console.error(err)
        handleError(err)
        return
      }
    }
    if (form.phoneNumberChecked && form.referralCode && !form.isSignUp) {
      this.state.formErr = {field: 'referralCode', message: 'Referral only available for new accounts'};
      this.forceUpdate()
      return
    }
    if (!form.verified) {
      if (!form.onVerificationCodeInput) {
        if (!this.verifier) {
          this.verifier = new this.props.me.firebase.auth.RecaptchaVerifier(this.recaptchaFun, {
            size: "invisible",
            callback: response => {
            }
          });
          this.recaptchaFun.style.display = "";
        }
        const recaptcha = this.verifier;
        consoleLog("sign in with phone number", phoneNumber)
        let result
        form.busy = true
        this.forceUpdate()
        try {
          result = await this.props.me.signInWithPhoneNumber(phoneNumber, recaptcha)
        } catch (err) {
          console.error(err)
          form.busy = false
          handleError(err)
          return
        }
        form.busy = false
        consoleLog(result)
        form.onVerificationCodeInput = async code => {
          try {
            form.busy = true
            this.forceUpdate()
            console.log('before confirm', result, code)
            const result1 = await result.confirm(code)
            console.log('after confirm', result1)
            form.busy = false
            form.user = result1.user
            form.onPasswordInput = null;
            form.onVerificationCodeInput = null;
            form.verified = true
            if (this.props.next) this.props.next()
            this.forceUpdate()
          } catch (err) {
            console.error('confirm exception', err)
            handleError(err)
            return
          }
        }
        this.forceUpdate()
      } else {
        if (!form.verificationCode) {
          this.state.formErr = {field: 'verificationCode', message: "Verification code is required."};
          this.forceUpdate()
          return
        }
        form.busy = true
        await form.onVerificationCodeInput(form.verificationCode)
        this.forceUpdate()
      }
    } else {
      /*
      if (!form.displayName) {
        const err = {field: 'displayName', message: 'First and Last Name is required.'}
        this.setState({formErr: err})
        return
      }
      if (!form.email) {
        const err = {field: 'email', message: 'Email Address is required.'}
        this.setState({formErr: err})
        return
      }
      if (!form.password) {
        const err = {field: 'password', message: 'Password is required.'}
        this.setState({formErr: err})
        return
        }
      const user = this.props.me.self
      const { error } = await this.props.me.saveProfile(form)
      if (error) {
        switch (error)
        {
          case 'username-in-use':
          {
            const err = {field: 'username', message: 'Username is in use by another account.'}
            this.setState({formErr: err})
          }
          break
          case 'email-in-use':
          {
            const err = {field: 'email', message: 'Email address is in use by another account.'}
            this.setState({formErr: err})
          }
          return
          case 'phone-number-in-use':
          {
            const err = {field: 'phoneNumber', message: 'Phone number is in use by another account.'}
            this.setState({formErr: err})
          }
          return 
        }
      }
      */
      this.props.next()
    }
  }

  createAccount = () => {
    this.setState({
      subpage: () => <KeyboardCreateAccount me={this.props.me} onChange={this.onChange} form={this.getForm()} formErr={this.state.formErr} next={this.signUp} back={this.props.back}/>
    })
  }

  render() {
    return <div className='keyboardLogin'>
             <div key='recaptcha-fun' id='recaptcha-fun' ref={ref=>{if (ref) this.recaptchaFun=ref}}/>
             {super.render()}
           </div>
  }

  renderContent() {
    let action = !this.props.back && this.createAccount
    let text
    let icon
    if (this.props.me.isAskAppOnly()) {
      text = 'Attunewise.ai'
      icon = IntelliKeyLogo2D
    }
    return <GetStarted me={this.props.me} action={action} text={text} icon={icon}>
              </GetStarted>
  }
}
